// China
export const locale = {
  TRANSLATOR: {
    SELECT: "选择你的语言"
  },

  MENU: {
    HOME: "主页",
    STORIES: "故事",
    CHAPTER: "章节",
    INCOME: "收入",
    PROFILE: "个人资料"
  },

  BOOK: {
    STORY_NAME: "书名",
    MY_STORIES: "我的故事",
    WORDS: "总字数",
    CHAPTERS: "总章节",
    SUBSCRIBERS: "订阅数量",
    VIEWS: "总观看数",
    NEW_STORY: "新建故事",
    LAST_UPDATED: "最后更新",
    EDIT_STORY: "编辑故事",
    DELETE_STORY: "删除故事",
    DELETE_STORY_CONFIRM: "确定删除该故事？",

    ONGOING: "进行中",
    COMPLETED: "已完结",

    UNDER_REVIEW: "审核中",
    APPLY_FOR_CONTRACT: "申请合同",
    REVIEW_DISAPPROVED: "审核不通过",
    REVIEW_APPROVED: "审核通过",
    REJECTED_REASON: "拒绝原因",
    YOU_HAVE_NOT_CREATED_A_STORY_YET: "你还没有创建故事。",
    PLEASE_CLICK_TO_UPLOAD: "请点击上传",
    CREATE_BASIC_INFORMATION_OF_BOOK_BEFORE_IMPORTING_NEW_CHAPTERS:
      "请先创建书籍基本信息，再导入章节",
    FILE_NAME: "文件名:",
    FILE_SIZE: "文件大小:",
    UPLOAD_DATE: "上传日期:",
    TXT_FORMAT_SUPPORTED: "TXT格式支持，文件大小不超过2M",
    HOW_TO_OBTAIN_TXT_FILES: "如何获取TXT文件？",
    FOR_MAC:
      "Mac用户:在页面中复制并粘贴Word文档，然后在页面中选择“导出为纯文本”；",
    FOR_WINDOWS:
      "Windows用户:以TXT格式导出Word文档，选择“Default”作为导出设置。",
    TXT_FORMAT_EXAMPLE: "TXT格式示例",
    FORMAT_TEMPLATE_DOWNLOAD: "格式模板下载",
    CHAPTER_INDEX: "章节索引",
    CHAPTER_NAME: "章节名称",
    CHAPTER_CONTENT: "章节内容",

    COVER_SIZE: "封面尺寸",
    SMALLER_THAN_2_MB: "小于2MB",
    FORMAT: "格式",

    TITLE: "标题",
    TITLE_ERROR: "书名（3-64个字符）",

    WORK_TYPE: "作品类型",
    ORIGINAL_WORK: "原作",
    TRANSLATION: "翻译",

    PEN_NAME_SHOW: "笔名显示",
    SHOW: "显示",
    HIDE: "隐藏",

    LANGUAGE: "语言",

    CONTENT_RATION: "适合年龄",
    ADULTS: "成人(18+)",
    YOUNG_ADULTS: "青少年(16+)",
    TEENS: "青年(13+)",

    GENRE: "分类",
    GENRE_ERROR: "请选择分类",

    TAGS: "标签",
    TAGS_ERROR: "请选择标签",
    ONLY_7_TAGS: "最多只能选择7个标签,每种类型至少选择1个",

    INTRODUCTION: "简介",
    INTRODUCTION_ERROR: "简介（10-500个字符）",

    OTHER_INFO: "其他信息",
    BOOK_ID: "书籍ID",
    SIGN_STATUS: "签约状态",
    SIGNED: "已签约",
    UNSIGNED: "未签约",
    CREATE_TIME: "创建时间",
    UPDATE_TIME: "更新时间",
    STATUS: "状态",

    MARK_AS_COMPLETED: "标记为已完结",
    MARK_AS_COMPLETED_MAKE_SURE_STORY: "请确保故事已完成，无法再次更新!",

    // 故事已完结
    STORY_FINISHED: "故事已完结"
  },

  CHAPTER: {
    NEW_CHAPTER: "新建章节",
    ALL_CHAPTER: "所有章节",
    DELETE_CHAPTER: "删除章节",

    CHAPTER: "章节",
    UNTITLED_CHAPTER: "未命名章节",
    WORDS: "字数",

    // 发布的状态
    UNPUBLISHED: "未发布", // 0未发布 算草稿
    PUBLISHING: "发布中", // 1发布中
    PUBLISHED: "已发布", // 2已发布成功
    PUBLISH_FAILED: "发布失败", // 3发布失败

    // 审核中
    NOT_REVIEWED: "未审核", // 0未审核
    REVIEW_DISAPPROVED: "未通过", // 1未通过
    REVIEW_PASSED: "审核通过", // 2审核通过
    REVIEWING: "审核中", // 3审核中

    // 其他
    SCHEDULED_PUBLISH: "定时发布",
    UNDER_REVIEW: "审核中",

    ENTER_CHAPTER_NAME: "输入章节名称",
    START_WRITING_HERE_500_3000_WORDS: "开始编写（500-3000字）",

    THIS_CHAPTER_SCHEDULED_PUBLISH_AT: "该章节定时发布于",

    PREVIOUS_CHAPTER: "上一章",

    PUBLISH_CHAPTER: "发布章节",

    PUBLISH_TIME: "发布时间",
    NOW: "现在",
    LATER: "定时",

    DELETE_CHAPTER_TIPS: "删除章节提示",
    DELETE_CHAPTER_TIPS_CONTENT:
      "删除章节后，该章节内容将被删除，确定要删除该章节吗？",

    LAST_CHAPTER_CANNOT_EMPTY: "最后一章不能为空!",

    EACH_CHAPTER_LESS_CHAPTER_3000_WORDS: "我们建议每章少于3000字"
  },

  AUTH: {
    ACCUMULATED_INCOME: "累计收入",
    MONEY_SIGN: "￥",

    NOTIFICATIONS: "通知",

    ACCOUNT_INFO: "账户信息",

    PROFILE: "个人资料",

    USER_NAME: "用户名",
    USER_NAME_ERROR: "用户名（3-32个字符）",

    GENDER: "性别",
    FEMALE: "男",
    MALE: "女",
    RATHER_NOT_SAY: "未知",

    BIRTHDAY: "生日",
    SELECT_YOUR_BIRTHDAY: "请选择你的生日",

    BIO: "简介",
    ENTER_YOUR_BIO: "请完成你的简介",
    BIO_ERROR: "简介（10-500个字符）",

    FACEBOOK_PROFILE_URL: "Facebook个人主页",
    FACEBOOK_ERROR: "Facebook个人主页（最多255个字符）",

    TWITTER_PROFILE: "Twitter个人主页",
    TWITTER_ERROR: "Twitter个人主页（最多255个字符）",

    IDENTITY_INFO: "身份信息",

    NATIONAL_ID: "身份证图片",
    CHANGE_NATIONAL_ID: "更换身份证",
    REMOVE_NATIONAL_ID: "删除身份证",

    ID_NUMBER: "身份证号",
    ENTER_YOUR_ID_NUMBER: "请输入身份证号",
    PLEASE_FINISH_THE_ID_NUMBER: "请完成身份证号",
    REAL_NAME: "真实姓名",
    PLEASE_FINISH_THE_REAL_NAME: "请完成真实姓名",
    BETWEEN_3_TO_32_CHARACTERS: "3-32个字符",
    COUNTRY_REGION: "国家/地区",
    PLEASE_FINISH_THE_COUNTRY_REGION: "请完成国家/地区",
    EMAIL: "邮箱",
    PLEASE_FINISH_THE_EMAIL: "请完成邮箱",
    WRONG_EMAIL_FORMAT: "邮箱格式错误",
    PHONE: "电话",
    ADDRESS: "详细地址",
    PLEASE_FINISH_THE_ADDRESS: "请完成地址",
    PEN_NAME: "笔名",
    PLEASE_FINISH_THE_PEN_NAME: "请完成笔名",
    EDITOR: "编辑",
    RANDOM: "随机",
    MANUAL: "手动",
    PLEASE_FINISH_THE_EDITOR: "请完成编辑",

    SELECT_YOUR_EDITOR: "请选择你的编辑",

    TRANSACTION_FEE:
      "交易费用由作者承担。以及交易费用的金额根据不同的付款方式。",
    PAYMENT_METHOD:
      "我们强烈建议您选择付款人作为付款方式接收付款的方法。付款人减少佣金费用，这将为您带来更高的效率。如果您所在的国家/地区目前没有付款人，请选择下面列出的其他付款选项。",
    DIFFERENT_COMMISSIONS:
      "贝宝或付款人收取的佣金各不相同基于您所在的国家。请查看您的本地PayPal或Payoneer网站获取准确信息。",
    TRANSACTION_FEE_BANK:
      "银行转账还将产生银行交易费交易费用因银行所在地区而异。请有关详细信息，请联系当地银行。",
    ANY_QUESTIONS: "如果您有任何问题或担忧，请随时请联系您的编辑",

    PAY_PAL_EMAIL: "PayPal邮箱",
    ILLEGAL_PAPAL_EMAIL: "非法PayPal邮箱",
    RECEIVER_EMAIL: "收款人邮箱",
    PLEASE_ENTER_RECEIVER_EMAIL: "请输入收款人邮箱",
    COLLECTION_SECURITY:
      "为确保收款安全，我们需要您确认我们是否可以在付款人网站上向您付款。请单击在“接收授权”按钮上进行确认。",
    RECEIVING_AUTHORIZATION: "接收授权",

    BANK: "银行卡",
    BANK_NAME: "银行名称",
    E_G_BANK_OF_CHINA: "例如：中国银行",
    PLEASE_FINISH_THE_BANK_NAME: "请完成银行名称",
    ACCOUNT_HOLDER_NAME: "帐户所有者姓名",
    E_G_LI: "例如：李四",
    PLEASE_FINISH_THE_ACCOUNT_HOLDER_NAME: "请完成帐户所有者姓名",
    ACCOUNT_NUMBER: "帐户号码",
    E_G_2188996688: "例如：2188996688",
    PLEASE_FINISH_THE_ACCOUNT_NUMBER: "请完成帐户号码",
    SWIFT_CODE: "SWIFT代码",
    E_G_ABCDEFGXXXX: "例如：ABCDEFGXXXX",
    PLEASE_FINISH_THE_SWIFT_CODE: "请完成SWIFT代码",

    DIALOG_CONFIRMATION_INFORMATION: "提交前确认信息正确无误"
  },
  OTHER: {
    SEE_ALL: "查看全部",
    CANCEL: "取消",
    CONFIRM: "确认",
    SAVE: "保存",
    NEXT: "下一步",
    TIPS: "提示",
    SUBMIT: "提交",
    CREATE: "创建",
    PUBLISH: "发布",

    LOADING: "加载中...",

    SUBMIT_NOW: "立即提交",
    MODIFY: "修改",

    NO_DATA_YET: "暂无数据",

    SIGN_OUT: "退出"
  },

  ERROR: {
    ERROR: "错误",
    SUCCESS: "成功",
    WARNING: "警告",

    CREATE_SUCCESS: "创建成功",
    CREATE_FAIL: "创建失败",
    CREATE_EXCEPTION: "创建异常",

    SAVE_SUCCESS: "保存成功",
    SAVE_FAIL: "保存失败",
    SAVE_EXCEPTION: "保存异常",

    EDITOR_SUCCESS: "修改成功",
    EDITOR_FAIL: "修改失败",
    EDITOR_EXCEPTION: "修改异常",

    DELETE_SUCCESS: "删除成功",
    DELETE_FAIL: "删除失败",
    DELETE_EXCEPTION: "删除异常",

    MARK_SUCCESS: "标记成功",
    MARK_FAIL: "标记失败",
    MARK_EXCEPTION: "标记异常",

    GET_DEALT_NEEDED_ERROR: "获取消息数据失败",
    GET_DEALT_NEEDED_EXCEPTION: "获取消息数据异常",

    GET_STORY_LIST_ERROR: "获取故事列表失败",
    GET_STORY_LIST_EXCEPTION: "获取故事列表异常",

    GET_USER_INFO_ERROR: "获取用户信息失败",
    GET_USER_INFO_EXCEPTION: "获取用户信息异常",

    COVER_SIZE_2_ERROR: "图片大小不能超过2M",
    FILE_SIZE_LARGER_ERROR: "文件大小不能超过2M",
    FILE_TYPE_ERROR: "文件类型错误",

    AVATAR_FORMAT_ERROR: "图片格式错误(JPG,PNG,JPEG)",

    EDIT_USER_INFO_SUCCESS: "修改用户信息成功",
    EDIT_USER_INFO_ERROR: "修改用户信息失败",
    EDIT_USER_INFO_EXCEPTION: "修改用户信息异常",

    GET_IDENTITY_INFO_ERROR: "获取身份信息失败",
    GET_IDENTITY_INFO_EXCEPTION: "获取身份信息异常",
    CREATE_IDENTITY_INFO_ERROR: "创建身份信息失败",
    CREATE_IDENTITY_INFO_EXCEPTION: "创建身份信息异常",

    GET_ACCOUNT_INFO_ERROR: "获取账户信息失败",
    GET_ACCOUNT_INFO_EXCEPTION: "获取账户信息异常",
    CREATE_ACCOUNT_INFO_ERROR: "创建账户信息失败",
    CREATE_ACCOUNT_INFO_EXCEPTION: "创建账户信息异常",

    CONTRACT_ERROR: "签约错误",
    CONTRACT_EXCEPTION: "签约异常",

    GET_GENRE_LIST_ERROR: "获取分类列表失败",
    GET_GENRE_LIST_EXCEPTION: "获取分类列表异常",

    GET_STORY_DETAIL_ERROR: "获取故事详情失败",
    GET_STORY_DETAIL_EXCEPTION: "获取故事详情异常",

    EGT_TAG_LIST_ERROR: "获取标签列表失败",
    EGT_TAG_LIST_EXCEPTION: "获取标签列表异常",

    UPLOAD_COVER_ERROR: "上传封面失败,请重试",

    GET_CHAPTER_LIST_ERROR: "获取章节列表失败",
    GET_CHAPTER_LIST_EXCEPTION: "获取章节列表异常",

    GET_CONTENT_ERROR: "获取内容失败",
    GET_CONTENT_EXCEPTION: "获取内容异常",

    SELECT_CHAPTER_ERROR: "请选择章节",

    PUBLISH_SUCCESS: "发布成功",
    PUBLISH_ERROR: "发布失败",
    PUBLISH_EXCEPTION: "发布异常",
    PUBLISH_AND_CANNOT_MODIFIED: "已发布无法修改",
    REVIEWING_AND_CANNOT_MODIFIED: "审核中无法修改",
    NOT_FIRST_CANNOT_PUBLISHED: "草稿非首次不能发布",
    NOT_FIRST_CANNOT_SAVE: "非草稿不能保存",

    PLEASE_FINISH_TITLE_CONTENT: "请完善标题和内容",
    PLEASE_FINISH_CHAPTER_TITLE: "请完善标题",
    PLEASE_FINISH_CHAPTER_CONTENT: "请完善内容",

    STORY_WITH_500_3000_WORDS: "请用500到3000个单词来完成这个故事",

    CHAPTER_TITLE_30_WORDS: "章节标题不能超过30个字",

    CHAPTER_CONTENT_CANNOT_BE_MORE: "章节内容不能超过",

    PUBLISH_TIME_ERROR: "发布时间不能小于当前时间",

    //章节标题字数在1到10之间
    CHAPTER_TITLE_ERROR: "章节标题字数在1到20之间",
    // 不能都是数字
    CHAPTER_TITLE_ERROR_1: "不能都是数字, 输入有意义的标题",
    // 不能都是Chapter 开头
    CHAPTER_TITLE_ERROR_2: "不能是Chapter开头，输入有意义的标题",

    //章节标题存在
    EXIST_CHAPTER_INDEX_DROP: "故事审核中，不可修改",

    STORY_AUDITING: ""
  },

  COUNTRY_REGION: {
    SS: "南非",
    ME: "马其顿",
    SX: "南苏丹",
    RS: "塞尔维亚",
    SM: "圣马力诺",
    AF: "阿富汗",
    AX: "奥兰群岛",
    AL: "阿尔巴尼亚",
    DZ: "阿尔及利亚",
    AS: "美属萨摩亚",
    AD: "安道尔",
    AO: "安哥拉",
    AI: "安圭拉",
    AQ: "南极洲",
    AG: "安提瓜和巴布达",
    AR: "阿根廷",
    AM: "亚美尼亚",
    AW: "阿鲁巴",
    AU: "澳大利亚",
    AT: "奥地利",
    AZ: "阿塞拜疆",
    BS: "巴哈马",
    BH: "巴林",
    BD: "孟加拉国",
    BB: "巴巴多斯",
    BY: "白俄罗斯",
    BE: "比利时",
    BZ: "伯利兹",
    BV: "布韦岛",
    BJ: "贝宁",
    BM: "百慕大",
    BT: "不丹",
    BO: "玻利维亚",
    BQ: "荷兰加勒比区",
    BA: "波斯尼亚和黑塞哥维那",
    BW: "博茨瓦纳",
    BR: "巴西",
    IO: "英属印度洋领地",
    BN: "英属印度洋领土",
    BG: "保加利亚",
    BF: "布基纳法索",
    BI: "布隆迪",
    KH: "柬埔寨",
    CM: "喀麦卡",
    CA: "加拿大",
    CV: "佛得角",
    KY: "开曼群岛",
    CF: "中非共和国",
    TD: "乍得",
    CL: "智利",
    CN: "中国",
    CX: "圣诞岛",
    CC: "科科斯群岛",
    CO: "哥伦比亚",
    KM: "科摩罗",
    CG: "刚果",
    CD: "刚果民主共和国",
    CK: "库克群岛",
    CR: "哥斯达黎加",
    CI: "科特迪瓦",
    HR: "克罗地亚",
    CU: "古巴",
    CW: "库拉索岛",
    CY: "塞浦路斯",
    CZ: "捷克共和国",
    DK: "丹麦",
    DJ: "吉布提",
    DM: "多米尼加",
    DO: "多米尼加共和国",
    EC: "厄瓜多尔",
    EG: "埃及",
    SV: "萨尔瓦多",
    GQ: "赤道几内亚",
    ER: "厄立特里亚",
    EE: "爱沙尼亚",
    ET: "埃塞俄比亚",
    FK: "福克兰群岛",
    FO: "法罗群岛",
    FJ: "斐济",
    FI: "芬兰",
    FR: "法国",
    GF: "法属圭亚那",
    PF: "法属波利尼西亚",
    TF: "法属南部领地",
    GA: "加蓬",
    GM: "冈比亚",
    GE: "格鲁吉亚",
    DE: "德国",
    GH: "加纳",
    GI: "直布罗陀",
    GR: "希腊",
    GL: "格陵兰",
    GD: "格林纳达",
    GP: "瓜德罗普",
    GU: "关岛",
    GT: "危地马拉",
    GG: "根西岛",
    GN: "几内亚",
    GW: "几内亚比绍",
    GY: "圭亚那",
    HT: "海地",
    HM: "赫德岛和麦克唐纳群岛",
    VA: "梵蒂冈",
    HN: "洪都拉斯",
    HK: "香港",
    HU: "匈牙利",
    IS: "冰岛",
    IN: "印度",
    ID: "印度尼西亚",
    IR: "伊朗",
    IQ: "伊拉克",
    IE: "爱尔兰",
    IM: "马恩岛",
    IL: "以色列",
    IT: "意大利",
    JM: "牙买加",
    JP: "日本",
    JE: "泽西岛",
    JO: "约旦",
    KZ: "哈萨克斯坦",
    KE: "肯尼亚",
    KI: "基里巴斯",
    KP: "朝鲜",
    KR: "韩国",
    KW: "科威特",
    KG: "吉尔吉斯斯坦",
    LA: "老挝",
    LV: "拉脱维亚",
    LB: "黎巴嫩",
    LS: "莱索托",
    LR: "利比里亚",
    LY: "利比亚",
    LI: "列支敦士登",
    LT: "立陶宛",
    LU: "卢森堡",
    MO: "中国澳门",
    MK: "马其顿",
    MG: "马达加斯加",
    MW: "马拉维",
    MY: "马来西亚",
    MV: "马尔代夫",
    ML: "马里",
    MT: "马耳他",
    MH: "马绍尔群岛",
    MQ: "马提尼克",
    MR: "毛里塔尼亚",
    MU: "毛里求斯",
    YT: "马约特岛",
    MX: "墨西哥",
    FM: "密克罗尼西亚联邦",
    MD: "摩尔多瓦",
    MC: "摩纳哥",
    MN: "蒙古",
    MS: "蒙特塞拉特岛",
    MA: "摩洛哥",
    MZ: "莫桑比克",
    MM: "缅甸",
    NA: "纳米比亚",
    NR: "瑙鲁",
    NP: "尼泊尔",
    NL: "荷兰",
    AN: "荷属安的列斯",
    NC: "新喀里多尼亚",
    NZ: "新西兰",
    NI: "尼加拉瓜",
    NE: "尼日尔",
    NG: "尼日利亚",
    NU: "纽埃",
    NF: "诺福克岛",
    MP: "北马里亚纳群岛",
    NO: "挪威",
    OM: "阿曼",
    PK: "巴基斯坦",
    PW: "帕劳",
    PS: "巴勒斯坦",
    PA: "巴拿马",
    PG: "巴布亚新几内亚",
    PY: "巴拉圭",
    PE: "秘鲁",
    PH: "菲律宾",
    PN: "皮特凯恩群岛",
    PL: "波兰",
    PT: "葡萄牙",
    PR: "波多黎各",
    QA: "卡塔尔",
    RE: "留尼旺",
    RO: "罗马尼亚",
    RU: "俄罗斯",
    RW: "卢旺达",
    BL: "圣巴泰勒米",
    SH: "圣赫勒拿",
    KN: "圣基茨和尼维斯",
    LC: "圣卢西亚",
    MF: "圣马丁",
    PM: "圣皮埃尔和密克隆",
    VC: "圣文森特和格林纳丁斯",
    WS: "西萨摩亚",
    ST: "圣多美和普林西比",
    SA: "沙特阿拉伯",
    SN: "塞内加尔",
    CS: "塞尔维亚和黑山",
    SC: "塞舌尔",
    SL: "塞拉利昂",
    SG: "新加坡",
    SK: "斯洛伐克",
    SI: "斯洛文尼亚",
    SB: "所罗门群岛",
    SO: "索马里",
    ZA: "南非",
    GS: "南乔治亚岛和南桑威奇群岛",
    ES: "西班牙",
    LK: "斯里兰卡",
    SD: "苏丹",
    SR: "苏里南",
    SJ: "斯瓦尔巴群岛和扬马延岛",
    SZ: "斯威士兰",
    SE: "瑞典",
    CH: "瑞士",
    SY: "叙利亚",
    TJ: "塔吉克斯坦",
    TW: "台湾",
    TZ: "坦桑尼亚",
    TH: "泰国",
    TL: "东帝汶",
    TG: "多哥",
    TK: "托克劳",
    TO: "汤加",
    TT: "特立尼达和多巴哥",
    TN: "突尼斯",
    TR: "土耳其",
    TM: "土库曼斯坦",
    TC: "特克斯和凯科斯群岛",
    TV: "图瓦卢",
    UG: "乌干达",
    UA: "乌克兰",
    AE: "阿拉伯联合酋长国",
    GB: "英国",
    US: "美国",
    UM: "美属萨摩亚",
    UY: "乌拉圭",
    UZ: "乌兹别克斯坦",
    VU: "瓦努阿图",
    VE: "委内瑞拉",
    VN: "越南",
    WF: "瓦利斯和富图纳群岛",
    EH: "西撒哈拉",
    YE: "也门",
    ZM: "赞比亚",
    ZW: "津巴布韦",
    VI: "美属维尔京群岛",
    VG: "英属维尔京群岛"
  }
};
