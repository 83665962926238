// Spain
export const locale = {
  TRANSLATOR: {
    SELECT: 'Elija su idioma',
  },

  MENU: {
    HOME: 'Hogar',
    STORIES: 'Historia',
    CHAPTER: 'Capítulo',
    INCOME: 'Ingresos',
    PROFILE: 'Contorno',
  },

  BOOK: {
    MY_STORIES: 'Mi Historia',
    WORDS: 'Palabras',
    CHAPTERS: 'Capítulo',
    SUBSCRIBERS: 'Suscriptor',
    VIEWS: 'Observaciones',
    NEW_STORY: 'Nueva historia',
    LAST_UPDATED: 'Última actualización',
    EDIT_STORY: 'Historia de la edición',
    DELETE_STORY: 'Borrar historia',
    DELETE_STORY_CONFIRM: '¿Estás seguro de que quieres borrar esta historia?',

    ONGOING: 'Ininterrumpido',
    COMPLETED: 'Completo',

    UNDER_REVIEW: 'En examen',
    APPLY_FOR_CONTRACT: 'Solicitud de contrato',
    REVIEW_DISAPPROVED: 'Examen no aprobado',
    REVIEW_APPROVED: 'Auditoría aprobada',
    REJECTED_REASON: 'Motivo del rechazo',
    YOU_HAVE_NOT_CREATED_A_STORY_YET: 'No has creado una historia.',
    PLEASE_CLICK_TO_UPLOAD: 'Por favor, haga clic para cargar.',
    CREATE_BASIC_INFORMATION_OF_BOOK_BEFORE_IMPORTING_NEW_CHAPTERS:
      'Cree información básica del libro antes de importar nuevos capítulos.',
    FILE_NAME: 'Nombre del archivo:',
    FILE_SIZE: 'Tamaño del archivo:',
    UPLOAD_DATE: 'Fecha de carga:',
    TXT_FORMAT_SUPPORTED: 'Formato TXT compatible',
    HOW_TO_OBTAIN_TXT_FILES: 'Cómo obtener archivos TXT?',
    FOR_MAC:
      'Para Mac: arrastre y suelte los archivos TXT en la carpeta de libros de la aplicación.',
    FOR_WINDOWS:
      'Para Windows: arrastre y suelte los archivos TXT en la carpeta de libros de la aplicación.',
    TXT_FORMAT_EXAMPLE: 'Ejemplo de formato TXT',
    FORMAT_TEMPLATE_DOWNLOAD: 'Descarga de plantilla de formato',
    CHAPTER_INDEX: 'Índice de capítulos',
    CHAPTER_NAME: 'Nombre del capítulo',
    CHAPTER_CONTENT: 'Contenido del capítulo',

    COVER_SIZE: 'Tamaño de la cubierta',
    SMALLER_THAN_2_MB: 'Más pequeño que 2 MB',
    FORMAT: 'Formato',

    TITLE: 'Título',
    TITLE_ERROR: 'Entre 3 y 64 caracteres',

    WORK_TYPE: 'Tipo de trabajo',
    ORIGINAL_WORK: 'Trabajo original',
    TRANSLATION: 'Trabajo de traducción',

    PEN_NAME_SHOW: 'Mostrar nombre de pluma',
    SHOW: 'Espectáculo',
    HIDE: 'Ocultar',

    LANGUAGE: 'Idioma',

    CONTENT_RATION: 'Ración de contenido',
    ADULTS: 'Adultos (18+)',
    YOUNG_ADULTS: 'Adultos jóvenes (16+)',
    TEENS: 'Adolescentes(13+)',

    GENRE: 'Género',
    GENRE_ERROR: 'Entre 3 y 64 caracteres',

    TAGS: 'Etiquetas',
    TAGS_ERROR: 'Seleccionar etiquetas',
    ONLY_7_TAGS:
      '7 opciones como máximo, seleccione 1 como mínimo en cada tipo',

    INTRODUCTION: 'Introducción',
    INTRODUCTION_ERROR: 'Introducción (10-500 caracteres)',

    OTHER_INFO: 'Otra información',
    BOOK_ID: 'Identificación del libro',
    SIGN_STATUS: 'Estado de la firma',
    SIGNED: 'Firmado',
    UNSIGNED: 'No firmado',
    CREATE_TIME: 'Crear tiempo',
    UPDATE_TIME: 'Tiempo de actualizacion',
    STATUS: 'Estado',

    MARK_AS_COMPLETED: 'Marcar como completado',
    MARK_AS_COMPLETED_MAKE_SURE_STORY:
      'Asegúrate de haber terminado la historia antes de marcarla como completada.',
  },

  CHAPTER: {
    NEW_CHAPTER: 'Nuevo capitulo',
    ALL_CHAPTER: 'Todo el capítulo',
    DELETE_CHAPTER: 'Eliminar capítulo',

    CHAPTER: 'Capítulo',
    UNTITLED_CHAPTER: 'Capítulo sin título',
    WORDS: 'Palabras',
    UNPUBLISHED: 'Inédito',
    UNDER_REVIEW: 'Bajo revisión',
    PUBLISHED: 'Publicado',
    REVIEW_DISAPPROVED: 'Reseña rechazada',

    ENTER_CHAPTER_NAME: 'Ingrese el nombre del capítulo',
    START_WRITING_HERE_500_3000_WORDS:
      'Comienza a escribir aquí (500-3000 palabras)',

    THIS_CHAPTER_SCHEDULED_PUBLISH_AT:
      'Este capítulo está programado para publicarse en',

    PREVIOUS_CHAPTER: 'Capítulo previo',

    PUBLISH_CHAPTER: 'Publicar capítulo',

    PUBLISH_TIME: 'Hora de publicación',
    NOW: 'Ahora',
    LATER: 'Luego',

    DELETE_CHAPTER_TIPS: '¿Eliminar consejos de capítulo?',
    DELETE_CHAPTER_TIPS_CONTENT: '¿Estás seguro de eliminar el capítulo?',

    LAST_CHAPTER_CANNOT_EMPTY: 'El último capítulo no puede estar vacío',

    EACH_CHAPTER_LESS_CHAPTER_3000_WORDS:
      'Cada capítulo menos de 3000 palabras.',
  },

  AUTH: {
    ACCUMULATED_INCOME: 'Renta Acumulada',
    MONEY_SIGN: '€',

    NOTIFICATIONS: 'Notificaciones',

    ACCOUNT_INFO: 'Informacion de cuenta',

    PROFILE: 'Perfil',

    USER_NAME: 'Nombre de usuario',
    USER_NAME_ERROR: 'Entre 3 y 32 caracteres',

    GENDER: 'Género',
    FEMALE: 'Femenino',
    MALE: 'Masculino',
    RATHER_NOT_SAY: 'Prefiero no decirlo',

    BIRTHDAY: 'Cumpleaños',
    SELECT_YOUR_BIRTHDAY: 'Por favor elige tu cumpleaños',

    BIO: 'biografía',
    ENTER_YOUR_BIO: 'Ingresa tu biografía',
    BIO_ERROR: 'Introducción (10-500 caracteres)',

    FACEBOOK_PROFILE_URL: 'URL del perfil de Facebook',
    FACEBOOK_ERROR: 'Perfil de Facebook (hasta 255 caracteres)',

    TWITTER_PROFILE: 'URL del perfil de Twitter',
    TWITTER_ERROR: 'Perfil de Twitter (hasta 255 caracteres)',

    IDENTITY_INFO: 'Información de identidad',

    NATIONAL_ID: 'Identificación nacional',
    CHANGE_NATIONAL_ID: 'Cambiar DNI',
    REMOVE_NATIONAL_ID: 'Quitar DNI',

    ID_NUMBER: 'Número de identificación',
    ENTER_YOUR_ID_NUMBER: 'Ingrese su número de identificación',
    PLEASE_FINISH_THE_ID_NUMBER:
      'Por favor termine su número de identificación',
    REAL_NAME: 'Nombre real',
    PLEASE_FINISH_THE_REAL_NAME: 'Por favor termine su nombre real',
    BETWEEN_3_TO_32_CHARACTERS: 'Entre 3 y 32 caracteres',
    COUNTRY_REGION: 'País/Región',
    PLEASE_FINISH_THE_COUNTRY_REGION: 'Por favor termine su país/región',
    EMAIL: 'Correo electrónico',
    PLEASE_FINISH_THE_EMAIL: 'Por favor termine su correo electrónico',
    WRONG_EMAIL_FORMAT: 'Formato de correo electrónico incorrecto',
    PHONE: 'Teléfono',
    ADDRESS: 'Dirección',
    PLEASE_FINISH_THE_ADDRESS: 'Por favor termine su dirección',
    PEN_NAME: 'Seudónimo',
    PLEASE_FINISH_THE_PEN_NAME: 'Por favor, termine su seudónimo',
    EDITOR: 'Editor',
    RANDOM: 'Aleatorio',
    MANUAL: 'Manual',
    PLEASE_FINISH_THE_EDITOR: 'Por favor termina tu editor',

    SELECT_YOUR_EDITOR: 'Por favor selecciona tu editor',

    TRANSACTION_FEE:
      'La tasa de transacción correrá a cargo del autor. Y el monto de la tarifa de transacción varía según los diferentes métodos de pago.',
    PAYMENT_METHOD:
      'Le recomendamos encarecidamente que elija Payoneer como método de pago para recibir el pago. Payoneer reduce la tarifa de comisión, lo que resultará en una mayor eficiencia para usted. Si Payoneer no está disponible actualmente en su país, seleccione otras opciones de pago que se enumeran a continuación.',
    DIFFERENT_COMMISSIONS:
      'La tarifa de comisión cobrada por PayPal o Payoneer varía según el país en el que se encuentre. Consulte el sitio web local de PayPal o Payoneer para obtener información exacta.',
    TRANSACTION_FEE_BANK:
      'La transferencia bancaria también generará una tarifa de transacción bancaria. La tarifa de transacción varía según las regiones de los bancos. Póngase en contacto con su banco local para obtener información detallada.',
    ANY_QUESTIONS:
      'Si tiene alguna pregunta o inquietud, no dude en comunicarse con su editor',

    PAY_PAL_EMAIL: 'E-mail de Paypal',
    ILLEGAL_PAPAL_EMAIL: 'Correo electrónico de PayPal ilegal',
    RECEIVER_EMAIL: 'Correo electrónico del receptor',
    PLEASE_ENTER_RECEIVER_EMAIL:
      'Por favor, introduzca el correo electrónico del destinatario',
    COLLECTION_SECURITY:
      'Para garantizar la seguridad de recibir el pago, necesitamos que confirme si podemos pagarle en el sitio web de payoneer. Haga clic en el botón de "Recibir autorización" para confirmarlo.',
    RECEIVING_AUTHORIZATION: 'Recepción de autorización',

    BANK: 'Banco',
    BANK_NAME: 'Nombre del banco',
    E_G_BANK_OF_CHINA: 'p.ej. Banco estatal',
    PLEASE_FINISH_THE_BANK_NAME: 'Por favor termine el nombre de su banco',
    ACCOUNT_HOLDER_NAME: 'nombre del titular de la cuenta',
    E_G_LI: 'p.ej. John Smith',
    PLEASE_FINISH_THE_ACCOUNT_HOLDER_NAME:
      'Por favor, termine el nombre del titular de su cuenta',
    ACCOUNT_NUMBER: 'Número de cuenta',
    E_G_2188996688: 'p.ej. 2188996688',
    PLEASE_FINISH_THE_ACCOUNT_NUMBER: 'Please finish your account number',
    SWIFT_CODE: 'Swift Code',
    E_G_ABCDEFGXXXX: 'e.g. ABCDGBMMXXX',
    PLEASE_FINISH_THE_SWIFT_CODE: 'Please finish your swift code',

    DIALOG_CONFIRMATION_INFORMATION: 'Confirmation Information',
  },
  OTHER: {
    SEE_ALL: 'See All',
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
    SAVE: 'Save',
    NEXT: 'Next',
    TIPS: 'Tips',
    SUBMIT: 'Submit',
    CREATE: 'Create',
    PUBLISH: 'Publish',

    LOADING: 'Loading',

    SUBMIT_NOW: 'Submit Now',
    MODIFY: 'Modify',

    NO_DATA_YET: 'No data yet',

    SIGN_OUT: 'Sign Out',
  },

  ERROR: {
    ERROR: 'Error',
    SUCCESS: 'Success',
    WARNING: 'Warning',

    CREATE_SUCCESS: 'Create Success',
    CREATE_FAIL: 'Create Fail',
    CREATE_EXCEPTION: 'Create Exception',

    SAVE_SUCCESS: 'Save Success',
    SAVE_FAIL: 'Save Fail',
    SAVE_EXCEPTION: 'Save Exception',

    EDITOR_SUCCESS: 'Editor Success',
    EDITOR_FAIL: 'Editor Fail',
    EDITOR_EXCEPTION: 'Editor Exception',

    DELETE_SUCCESS: 'Delete Success',
    DELETE_FAIL: 'Delete Fail',
    DELETE_EXCEPTION: 'Delete Exception',

    MARK_SUCCESS: 'Mark Success',
    MARK_FAIL: 'Mark Fail',
    MARK_EXCEPTION: 'Mark Exception',

    GET_DEALT_NEEDED_ERROR: 'Get Dealt Needed Error',
    GET_DEALT_NEEDED_EXCEPTION: 'Get Dealt Needed Exception',

    GET_STORY_LIST_ERROR: 'Get Story List Error',
    GET_STORY_LIST_EXCEPTION: 'Get Story List Exception',

    GET_USER_INFO_ERROR: 'Get User Info Error',
    GET_USER_INFO_EXCEPTION: 'Get User Info Exception',

    COVER_SIZE_2_ERROR: 'Picture size cannot exceed 2MB',
    FILE_SIZE_LARGER_ERROR: 'File size cannot exceed 2MB',
    FILE_TYPE_ERROR: 'Wrong file type',

    AVATAR_FORMAT_ERROR: 'Avatar format error',

    EDIT_USER_INFO_SUCCESS: 'Edit User Info Success',
    EDIT_USER_INFO_ERROR: 'Edit User Info Error',
    EDIT_USER_INFO_EXCEPTION: 'Edit User Info Exception',

    GET_IDENTITY_INFO_ERROR: 'Get Identity Info Error',
    GET_IDENTITY_INFO_EXCEPTION: 'Get Identity Info Exception',
    CREATE_IDENTITY_INFO_ERROR: 'Create Identity Info Error',
    CREATE_IDENTITY_INFO_EXCEPTION: 'Create Identity Info Exception',

    GET_ACCOUNT_INFO_ERROR: 'Get Account Info Error',
    GET_ACCOUNT_INFO_EXCEPTION: 'Get Account Info Exception',
    CREATE_ACCOUNT_INFO_ERROR: 'Create Account Info Error',
    CREATE_ACCOUNT_INFO_EXCEPTION: 'Create Account Info Exception',

    CONTRACT_ERROR: 'Contract Error',
    CONTRACT_EXCEPTION: 'Contract Exception',

    GET_GENRE_LIST_ERROR: 'Get Genre List Error',
    GET_GENRE_LIST_EXCEPTION: 'Get Genre List Exception',

    GET_STORY_DETAIL_ERROR: 'Get Story Detail Error',
    GET_STORY_DETAIL_EXCEPTION: 'Get Story Detail Exception',

    EGT_TAG_LIST_ERROR: 'Get Tag List Error',
    EGT_TAG_LIST_EXCEPTION: 'Get Tag List Exception',

    UPLOAD_COVER_ERROR: 'Failed to upload cover, please try again',

    GET_CHAPTER_LIST_ERROR: 'Get Chapter List Error',
    GET_CHAPTER_LIST_EXCEPTION: 'Get Chapter List Exception',

    GET_CONTENT_ERROR: 'Get Content Error',
    GET_CONTENT_EXCEPTION: 'Get Content Exception',

    SELECT_CHAPTER_ERROR: 'Select Chapter Error',

    PUBLISH_SUCCESS: 'Publish Success',
    PUBLISH_ERROR: 'Publish Error',
    PUBLISH_EXCEPTION: 'Publish Exception',
    PUBLISH_AND_CANNOT_MODIFIED: 'Publish and cannot modified',

    PLEASE_FINISH_TITLE_CONTENT: 'Please finish title and content',

    STORY_WITH_500_3000_WORDS: 'Story with 500-3000 words',

    CHAPTER_TITLE_30_WORDS: 'Chapter title 30 words',

    CHAPTER_CONTENT_CANNOT_BE_MORE:
      'Chapter content cannot be more than 3000 words',

    PUBLISH_TIME_ERROR: 'Publish time error',
  },

  COUNTRY_REGION: {
    AF: 'Afghanistan',
    AX: 'Åland Islands',
    AL: 'Albania',
    DZ: 'Algeria',
    AS: 'American Samoa',
    AD: 'Andorra',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctica',
    AG: 'Antigua and Barbuda',
    AR: 'Argentina',
    AM: 'Armenia',
    AW: 'Aruba',
    AU: 'Australia',
    AT: 'Austria',
    AZ: 'Azerbaijan',
    BS: 'Bahamas',
    BH: 'Bahrain',
    BD: 'Bangladesh',
    BB: 'Barbados',
    BY: 'Belarus',
    BE: 'Belgium',
    BZ: 'Belize',
    BJ: 'Benin',
    BM: 'Bermuda',
    BT: 'Bhutan',
    BO: 'Bolivia, Plurinational State of',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BA: 'Bosnia and Herzegovina',
    BW: 'Botswana',
    BV: 'Bouvet Island',
    BR: 'Brazil',
    IO: 'British Indian Ocean Territory',
    BN: 'Brunei Darussalam',
    BG: 'Bulgaria',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodia',
    CM: 'Cameroon',
    CA: 'Canada',
    CV: 'Cape Verde',
    KY: 'Cayman Islands',
    CF: 'Central African Republic',
    TD: 'Chad',
    CL: 'Chile',
    CN: 'China',
    CX: 'Christmas Island',
    CC: 'Cocos (Keeling) Islands',
    CO: 'Colombia',
    KM: 'Comoros',
    CG: 'Congo',
    CD: 'Congo, the Democratic Republic of the',
    CK: 'Cook Islands',
    CR: 'Costa Rica',
    CI: "Côte d'Ivoire",
    HR: 'Croatia',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Cyprus',
    CZ: 'Czech Republic',
    DK: 'Denmark',
    DJ: 'Djibouti',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    EG: 'Egypt',
    SV: 'El Salvador',
    GQ: 'Equatorial Guinea',
    ER: 'Eritrea',
    EE: 'Estonia',
    ET: 'Ethiopia',
    FK: 'Falkland Islands (Malvinas)',
    FO: 'Faroe Islands',
    FJ: 'Fiji',
    FI: 'Finland',
    FR: 'France',
    GF: 'French Guiana',
    PF: 'French Polynesia',
    TF: 'French Southern Territories',
    GA: 'Gabon',
    GM: 'Gambia',
    GE: 'Georgia',
    DE: 'Germany',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Greece',
    GL: 'Greenland',
    GD: 'Grenada',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernsey',
    GN: 'Guinea',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HT: 'Haiti',
    HM: 'Heard Island and McDonald Islands',
    VA: 'Holy See (Vatican City State)',
    HN: 'Honduras',
    HK: 'Hong Kong',
    HU: 'Hungary',
    IS: 'Iceland',
    IN: 'India',
    ID: 'Indonesia',
    IR: 'Iran, Islamic Republic of',
    IQ: 'Iraq',
    IE: 'Ireland',
    IM: 'Isle of Man',
    IL: 'Israel',
    IT: 'Italy',
    JM: 'Jamaica',
    JP: 'Japan',
    JE: 'Jersey',
    JO: 'Jordan',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: "Korea, Democratic People's Republic of",
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KG: 'Kyrgyzstan',
    LA: "Lao People's Democratic Republic",
    LV: 'Latvia',
    LB: 'Lebanon',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libya',
    LI: 'Liechtenstein',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macedonia, the former Yugoslav Republic of',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaysia',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malta',
    MH: 'Marshall Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MU: 'Mauritius',
    YT: 'Mayotte',
    MX: 'Mexico',
    FM: 'Micronesia, Federated States of',
    MD: 'Moldova, Republic of',
    MC: 'Monaco',
    MN: 'Mongolia',
    ME: 'Montenegro',
    MS: 'Montserrat',
    MA: 'Morocco',
    MZ: 'Mozambique',
    MM: 'Myanmar',
    NA: 'Namibia',
    NR: 'Nauru',
    NP: 'Nepal',
    NL: 'Netherlands',
    NC: 'New Caledonia',
    NZ: 'New Zealand',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigeria',
    NU: 'Niue',
    NF: 'Norfolk Island',
    MP: 'Northern Mariana Islands',
    NO: 'Norway',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palau',
    PS: 'Palestinian Territory, Occupied',
    PA: 'Panama',
    PG: 'Papua New Guinea',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    PN: 'Pitcairn',
    PL: 'Poland',
    PT: 'Portugal',
    PR: 'Puerto Rico',
    QA: 'Qatar',
    RE: 'Réunion',
    RO: 'Romania',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    BL: 'Saint Barthélemy',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    KN: 'Saint Kitts and Nevis',
    LC: 'Saint Lucia',
    MF: 'Saint Martin (French part)',
    PM: 'Saint Pierre and Miquelon',
    VC: 'Saint Vincent and the Grenadines',
    WS: 'Samoa',
    SM: 'San Marino',
    ST: 'Sao Tome and Principe',
    SA: 'Saudi Arabia',
    SN: 'Senegal',
    RS: 'Serbia',
    SC: 'Seychelles',
    SL: 'Sierra Leone',
    SG: 'Singapore',
    SX: 'Sint Maarten (Dutch part)',
    SK: 'Slovakia',
    SI: 'Slovenia',
    SB: 'Solomon Islands',
    SO: 'Somalia',
    ZA: 'South Africa',
    GS: 'South Georgia and the South Sandwich Islands',
    SS: 'South Sudan',
    ES: 'Spain',
    LK: 'Sri Lanka',
    SD: 'Sudan',
    SR: 'Suriname',
    SJ: 'Svalbard and Jan Mayen',
    SZ: 'Swaziland',
    SE: 'Sweden',
    CH: 'Switzerland',
    SY: 'Syrian Arab Republic',
    TW: 'Taiwan, Province of China',
    TJ: 'Tajikistan',
    TZ: 'Tanzania, United Republic of',
    TH: 'Thailand',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokelau',
    TO: 'Tonga',
    TT: 'Trinidad and Tobago',
    TN: 'Tunisia',
    TR: 'Turkey',
    TM: 'Turkmenistan',
    TC: 'Turks and Caicos Islands',
    TV: 'Tuvalu',
    UG: 'Uganda',
    UA: 'Ukraine',
    AE: 'United Arab Emirates',
    GB: 'United Kingdom',
    US: 'United States',
    UM: 'United States Minor Outlying Islands',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VU: 'Vanuatu',
    VE: 'Venezuela, Bolivarian Republic of',
    VN: 'Viet Nam',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    WF: 'Wallis and Futuna',
    EH: 'Western Sahara',
    YE: 'Yemen',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
  },
};
