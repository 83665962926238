import ApiService from '@/core/services/api.service';
import qs from 'qs';

// action types
export const FETCH_ALL_BOOKS = 'fetchAllBooks';

// mutation types
export const SET_ALL_BOOKS = 'setAllBooks';
export const APPEND_BOOKS = 'appendBooks';
export const SET_ERROR = 'setError';
export const SET_BOOK_TITLE = 'setBookTitle';

// 用户的书籍列表
// 列表中每一项是一个book对象
// book对象里存储章节列表
// 章节列表中每一项是一个chpater对象
// chpater对象存储章节的信息和内容
const state = {
  errors: null,

  bookList: [
    {
      book_id: 3853, //书的ID
      book_title: 'LET ME GAME IN PEACE', //书名
      word_count: 120000, //字数
      chapter_count: 1000, //章节数
      subscribes: 1000, //订阅数
      views: 900, //浏览
      status: 0, //数据状态是否完结 0-续更 1-完结
      review_status: 0, //0：草稿 2：审核中 3：审核不通过 4：上架 5：下架 6：审核通过 7：具备签约  8:已签约
      review_msg: '', //审核提示消息
      created: '', //书籍创建时间
      updated: '', //更新时间
      latest_updated: '', //最后更新章节时间
      cover_url: '', //封面地址

      chapterList: [
        {
          chapter_id: 137438, //章节ID
          chapter_title: '第1章', //章节标题
          word_count: 6296, //章节字数
          status: 0, // 0：草稿 1：审核中 2：已发布 3：审核未通过
          submit_type: true, // 发布章节类型  立即发送为  False  选择时间发送 时间 true
          submit_time: '2018-10-30 14:45:36', //选择时间发送 时间   如 立即发送则为null
          created: '2018-10-30 14:45:36', //创建时间
          updated: '2022-01-07 11:09:29', //修改时间
        },
      ],
    },
  ],
};

const getters = {
  // 得到书籍列表
  stateBookList(state) {
    return state.bookList;
  },

  // 数据书籍数量
  stateBookListCount(state) {
    return state.bookList.length;
  },
};

const mutations = {
  // 设置当前错误
  [SET_ERROR](state, payload) {
    state.errors = payload;
  },

  // 设置书籍列表(覆盖状态列表)
  [SET_ALL_BOOKS](state, payload) {
    state.bookList = payload;
  },

  // 追加书籍列表(追加状态列表)
  [APPEND_BOOKS](state, payload) {
    state.bookList = [...state.bookList, payload];
  },

  // 设置书的标题
  [SET_BOOK_TITLE](state, payload) {
    state.bookList[0].book_title = payload;
  },
};

const actions = {
  // 拉取书籍列表
  [FETCH_ALL_BOOKS](context, payload) {
    return new Promise((resolve) => {
      ApiService.post('author/get_all_books', payload)
        .then(({ data }) => {
          if (data.code === 0) {
            // context.commit(SET_AUTH, data.body)
            resolve(data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
