import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import qs from 'qs';

// action types
export const VERIFY_AUTH = 'verifyAuth';
export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const UPDATE_USER = 'updateUser';
export const SET_AVATAR = 'setAvatar';
export const SET_CONTRACT = 'setContract';
export const SET_PEN_NAME = 'setPenName';

// mutation types
export const PURGE_AUTH = 'logOut';
export const SET_AUTH = 'setUser';
export const SET_ERROR = 'setError';
export const SET_TOKEN = 'setToken';

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve) => {
      ApiService.post('author/login', qs.stringify(credentials))
        .then(({ data }) => {
          if (data.code === 0) {
            context.commit(SET_AUTH, data.body);
            resolve(data);
          } else {
            context.commit(SET_ERROR, data.message);
          }
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },

  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('users', {
        user: credentials,
      })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },

  [VERIFY_AUTH](context) {
    return new Promise((resolve, reject) => {
      let flag = !!JwtService.getToken();
      if (flag) {
        ApiService.setHeader();
        ApiService.get('author/verify')
          .then(({ data }) => {
            if (data.code === 0) {
              // // 验证通过服务器返回新的token字串
              // let tk = data.body.token;
              // context.commit(SET_TOKEN, tk);
              resolve(data);
            } else {
              // 如果服务器验证token过期跳转到登录页
              context.commit(PURGE_AUTH);
              resolve(data);
            }
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data.errors);
            reject(response);
          });
      } else {
        context.commit(PURGE_AUTH);
        resolve();
      }
    });
  },

  [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = {
      email,
      username,
      bio,
      image,
    };
    if (password) {
      user.password = password;
    }

    return ApiService.put('user', user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  },

  [SET_AVATAR](context, url) {
    context.commit(SET_AVATAR, url);
  },
  [SET_CONTRACT](context, contract) {
    context.commit(SET_CONTRACT, contract);
  },
  [SET_PEN_NAME](context, penName) {
    context.commit(SET_PEN_NAME, penName);
  },

  [SET_TOKEN](context, token) {
    context.commit(SET_TOKEN, token);
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },

  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },

  [SET_AVATAR](state, url) {
    state.user.avatar_url = url;
  },
  [SET_CONTRACT](state, contract) {
    state.user.contract = contract;
  },

  [SET_TOKEN](state, token) {
    state.user.token = token;
    JwtService.saveToken(state.user.token);
  },

  [SET_PEN_NAME](state, penName) {
    state.user.pen_name = penName;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
