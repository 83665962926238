// France
export const locale = {
  TRANSLATOR: {
    SELECT: 'Choisissez Votre Langue',
  },

  MENU: {
    HOME: 'Accueil',
    STORIES: 'Livres',
    CHAPTER: 'Chapitre',
    INCOME: 'Revenu',
    PROFILE: 'Information Personnelle',
  },

  BOOK: {
    // 输入故事名称
    INPUT_STORY_NAME: 'Entrez le nom du livre',
    //输入故事ID
    INPUT_STORY_ID: "Entrez l'ID du livre",
    MY_STORIES: 'Mes livres',
    WORDS: 'Mots',
    CHAPTERS: 'Chapitres',
    SUBSCRIBERS: 'Abonnés',
    VIEWS: 'Vues',
    NEW_STORY: 'Créer un nouveau livre',
    LAST_UPDATED: 'Dernière mise à jour',
    EDIT_STORY: 'Modifier le Livre',
    DELETE_STORY: 'Supprimer le Livre',
    DELETE_STORY_CONFIRM: 'Sûr de supprimer ce livre?',

    ONGOING: 'En cours',
    COMPLETED: 'Complété',

    UNDER_REVIEW: "En cours d'examiner",
    APPLY_FOR_CONTRACT: 'Demander un contrat',
    REVIEW_DISAPPROVED: 'Examination Échouée',
    REVIEW_APPROVED: 'Examination Réussie',
    REJECTED_REASON: 'Raison du rejet',
    YOU_HAVE_NOT_CREATED_A_STORY_YET:
      "Vous n'avez pas encore créé une histoire.",
    PLEASE_CLICK_TO_UPLOAD: 'Veuillez cliquer ici pour télécharger',
    // 批量导入章节
    BATCH_IMPORT_CHAPTER: 'Importer des chapitres',

    // 章节上传失败，点击重试
    UPLOAD_FAILED_CLICK_TO_RETRY:
      'Téléchargement échoué，cliquez ici pour réessayer',
    //   章节上传中
    CHAPTER_UPLOADING: 'Téléchargement est en cours',
    //  章节重试上传成功
    CHAPTER_RETRY_UPLOAD_SUCCESS: 'Téléchargement réussi',
    // 重试失败请重新上传章节
    CHAPTER_RETRY_UPLOAD_FAILED: 'Téléchargement échoué，veuillez réessayer',
    // 章节正在后台上传，请稍后5分钟
    CHAPTER_UPLOADING_PLEASE_WAIT:
      'Téléchargement va durer 5 minutes, veuillez patienter.',
    // 故事已完结
    STORY_FINISHED: 'Histoire Complétée',
    CREATE_BASIC_INFORMATION_OF_BOOK_BEFORE_IMPORTING_NEW_CHAPTERS:
      'Veuillez remplir les informations essentielles de votre livre, puis télécharger les chapitres.',
    FILE_NAME: 'Titre de fichier',
    FILE_SIZE: 'Taille de fichier',
    UPLOAD_DATE: 'Date de téléchargement',
    TXT_FORMAT_SUPPORTED:
      'Un fichier de TXT est accepté，sa taille ne doit pas dépasser 2M',
    HOW_TO_OBTAIN_TXT_FILES: 'Comment obtenir un fichier de TXT？',
    FOR_MAC:
      "Utilisateurs de Mac : Copiez et collez le document Word dans la page, puis sélectionnez 'Exporter en tant que texte'.",
    FOR_WINDOWS:
      "Utilisateurs de Windows : Exportez le document Word au format TXT et sélectionnez 'Default' comme mode d'exportation.",
    TXT_FORMAT_EXAMPLE: 'Exemple du format TXT',
    FORMAT_TEMPLATE_DOWNLOAD: 'Modèle de format à télécharger',
    CHAPTER_INDEX: 'Index des chapitres',
    CHAPTER_NAME: 'Titre du chapitre',
    CHAPTER_CONTENT: 'Contenu du chapitre ',

    COVER_SIZE: 'Taille de couverture',
    SMALLER_THAN_2_MB: 'Plus petit que 2MB',
    FORMAT: 'Format de fichier',

    TITLE: 'Titre',
    TITLE_ERROR: 'Titre du livre（3-64 caractères ）',

    WORK_TYPE: 'Type du livre',
    ORIGINAL_WORK: 'Original',
    TRANSLATION: 'Traduit',

    PEN_NAME_SHOW: 'Affichage du Nom de plume',
    SHOW: 'Affiché',
    HIDE: 'Caché',

    LANGUAGE: 'Langue',

    CONTENT_RATION: 'Âge adapté',
    ADULTS: 'Adulte(18+)',
    YOUNG_ADULTS: 'Adolescent(16+)',
    TEENS: 'Gamin(13+)',

    GENRE: 'Genre',
    //选择分类
    SELECT_GENRE: 'Sélectionnez le genre',
    GENRE_ERROR: 'Sélectionner le genre',

    TAGS: 'Etiquettes',
    TAGS_ERROR: 'Veuillez sélectionner vos étiquettes',
    ONLY_7_TAGS:
      "7 étiquettes peut être sélectionné au maximum,sélectionnez au moins un de chaque type d'étiquette",

    INTRODUCTION: 'Synopsis',
    INTRODUCTION_ERROR:
      'Donnez une description à votre livre. (De 100 à 500 mots)',

    OTHER_INFO: 'Informations complémentaires',
    BOOK_ID: 'Numéro du livre',
    SIGN_STATUS: 'Statut du contrat',
    SIGNED: 'Signé',
    UNSIGNED: 'En attente de signature',
    CREATE_TIME: 'Temps de création',
    UPDATE_TIME: 'Temps de mise à jour',
    STATUS: 'Statut',

    MARK_AS_COMPLETED: 'Marquer comme terminé',
    MARK_AS_COMPLETED_MAKE_SURE_STORY:
      "Veuillez vous assurer que l'histoire est terminée, les nouveaux chapitres ne peuvent pas être ajoutés une fois qu'elle est marquée comme terminée!",
    MARK_APPLICATION: 'Marquer applicationa',
    IN_APPLICATION: 'Dans application',
    APPLICATION_FAILED: 'Application échouée',
  },

  CHAPTER: {
    NEW_CHAPTER: 'Créer un nouveau chapitre',
    ALL_CHAPTER: 'Tous les chapitres',
    DELETE_CHAPTER: 'Supprimer le chapitre',

    CHAPTER: 'Chapitre',
    UNTITLED_CHAPTER: 'Chapitre sans titre',
    WORDS: 'Mots',
    UNPUBLISHED: 'Non Publié',
    UNDER_REVIEW: 'Sous examen',
    // 审核中
    REVIEWING: 'En cours d’examen',
    // 定时发布
    SCHEDULED_PUBLISH: 'Publication planifiée',
    PUBLISHED: 'Publié',
    REVIEW_DISAPPROVED: 'Examen échoué',

    ENTER_CHAPTER_NAME: 'Entrez le nom du chapitre',
    START_WRITING_HERE_500_3000_WORDS: 'Commencez à écrire (500-3000 mots)',

    THIS_CHAPTER_SCHEDULED_PUBLISH_AT: 'Ce chapitre publiera à',

    PREVIOUS_CHAPTER: 'Chapitre précédent',

    PUBLISH_CHAPTER: 'Publier les chapitres',

    PUBLISH_TIME: 'Temps de publication',
    NOW: 'Maintenant',
    LATER: 'Plus tard',

    DELETE_CHAPTER_TIPS: 'Note avant de supprimer les chapitres',
    DELETE_CHAPTER_TIPS_CONTENT:
      'Après avoir supprimé ce chapitre, le contenu ne sera plus affiché. Êtes-vous sûr de vouloir supprimer ce chapitre?',

    LAST_CHAPTER_CANNOT_EMPTY: 'Le dernier chapitre ne peut être vide!',

    EACH_CHAPTER_LESS_CHAPTER_3000_WORDS:
      "Veuillez n'écrire plus de 3000 mots par chapitre",

    // 存在未发布章节
    EXIST_UNPUBLISHED_CHAPTER: 'Chapitre non publié présent',
  },

  AUTH: {
    // 选择规则
    SELECT_RULE: 'Choisir une règle',
    // 推荐人
    REFERFNCE: 'Recommandé par',
    // 点击绑定
    CLICK_TO_BIND: 'Cliquez sur Binding',
    //存在绑定关系，无法重复绑定
    EXIST_BIND_RELATION:
      'Il existe une relation de liaison, impossible de répéter la liaison',
    MONTH_UPDATED_WORDS: 'mots mis à jour dans le mois naturel en cours',
    ACCUMULATED_INCOME: 'Revenu accumulé',
    MONEY_SIGN: '$',

    NOTIFICATIONS: 'Notification',

    ACCOUNT_INFO: 'Information de compte',

    PROFILE: 'Profil',

    USER_NAME: "Nom d'utilisateur",
    USER_NAME_ERROR: "Nom d'utilisateur（3-32 mots）",

    GENDER: 'Sexe',
    FEMALE: 'Male',
    MALE: 'Female',
    RATHER_NOT_SAY: 'Les Autres',

    BIRTHDAY: 'Anniversaire',
    SELECT_YOUR_BIRTHDAY: 'Sélectionner votre anniversaire ',

    BIO: 'Présentation',
    ENTER_YOUR_BIO: 'Veuillez compléter votre présentation',
    BIO_ERROR: 'Presentation（10-500 mots）',

    FACEBOOK_PROFILE_URL: 'Profil de votre FB',
    FACEBOOK_ERROR: 'Profil de votre FB（255 caractères au maximum）',

    TWITTER_PROFILE: 'Profil de votre Twitter',
    TWITTER_ERROR: 'Profil de votre Twitter（255 caractères au maximum）',

    IDENTITY_INFO: "Informations d'identité",

    NATIONAL_ID: "Photo de votre carte d'identité",
    CHANGE_NATIONAL_ID: "Remplacer  la  carte d'identité",
    REMOVE_NATIONAL_ID: "Supprimer la carte d'identité",

    ID_NUMBER: "Numéro d'identification",
    ENTER_YOUR_ID_NUMBER: "Veuillez entrer votre numéro d'identification",
    PLEASE_FINISH_THE_ID_NUMBER:
      "Veuillez compléter votre  numéro d'identification",
    REAL_NAME: 'Nom réel',
    PLEASE_FINISH_THE_REAL_NAME: 'Veuillez compléter votre nom réel',
    BETWEEN_3_TO_32_CHARACTERS: '3 à 32 caractères',
    COUNTRY_REGION: 'Pays/Région',
    PLEASE_FINISH_THE_COUNTRY_REGION: 'Veuillez compléter le pays/la région',
    EMAIL: 'Adresse d’e-mail',
    PLEASE_FINISH_THE_EMAIL: 'Veuillez compléter votre adresse d’e-mail',
    WRONG_EMAIL_FORMAT: 'Format de votre adresse d’E-mail  est faux ',
    PHONE: 'Numéro  de votre téléphone',
    PLEASE_FINISH_THE_PHONE: 'Veuillez compléter votre numéro de téléphone',
    ADDRESS: 'Adresse détaillée',
    PLEASE_FINISH_THE_ADDRESS: 'Veuillez compléter votre adresse  détaillée',
    PEN_NAME: 'Nom de plume',
    PLEASE_FINISH_THE_PEN_NAME: 'Veuillez compléter votre nom de plume',
    EDITOR: 'Éditeur',
    RANDOM: 'De façon aléatoire',
    MANUAL: 'Opération manuelle',
    PLEASE_FINISH_THE_EDITOR: 'Veuillez  compléter votre modification ',

    SELECT_YOUR_EDITOR: 'Veuillez choisir votre éditeur',

    TRANSACTION_FEE:
      "Les frais engagés dans le transfert sont à la charge de l'auteur et ce coût variera en fonction des différents modes de paiement.。",
    PAYMENT_METHOD:
      "Nous vous recommandons vivement de choisir Payoneer comme mode de paiement, car les frais sont moins élevés et le paiement est plus rapide. Si vous êtes dans un pays où Payoneer n'est pas disponible actuellement, veuillez choisir l'une des autres méthodes de paiement indiquées ci-dessous.",
    DIFFERENT_COMMISSIONS:
      'Paypal ou Payoneer peuvent charger des frais différents selon le pays et région. Veuillez consulter les sites officiels de PayPal et de Payoneer dans votre pays ou région pour obtenir des informations précises.',
    TRANSACTION_FEE_BANK:
      'Les virements bancaires entraînent des frais de transfert, dont le montant varie en fonction du pays et de la région où se trouve la banque. Pour des informations plus détaillées, veuillez contacter votre banque locale.',
    ANY_QUESTIONS:
      "Si vous avez des questions ou des soucis, n'hésitez pas à contacter votre éditeur.",

    PAY_PAL_EMAIL: 'E-mail de PayPal',
    ILLEGAL_PAPAL_EMAIL: 'Erreur de format dans votre e-mail address de PayPal',
    RECEIVER_EMAIL: 'E-mail du bénéficiaire',
    PLEASE_ENTER_RECEIVER_EMAIL:
      "Veuillez saisir l'adresse électronique du bénéficiaire",
    COLLECTION_SECURITY:
      "Pour garantir la sécurité de votre paiement, nous avons besoin que vous confirmiez que nous pouvons vous payer sur le site officiel de Payoneer. Veuillez cliquer sur le bouton 'Autorisation depaiement' pour le confirmer.",
    RECEIVING_AUTHORIZATION: 'Autorisation de paiement',

    BANK: 'Carte bancaire',
    BANK_NAME: 'Nom de la banque',
    E_G_BANK_OF_CHINA: 'Par exemple: Banque de France',
    PLEASE_FINISH_THE_BANK_NAME: 'Veuillez compléter le nom de la banque',
    ACCOUNT_HOLDER_NAME: 'Nom du propriétaire du compte',
    E_G_LI: 'Par exemple : William Macron',
    PLEASE_FINISH_THE_ACCOUNT_HOLDER_NAME:
      'Veuillez compléter le nom du propriétaire du compte',
    ACCOUNT_NUMBER: 'Numéro de compte',
    E_G_2188996688: 'Par exemple: 1234 4321 1123 2243',
    PLEASE_FINISH_THE_ACCOUNT_NUMBER: 'Veuillez compléter le numéro de compte',
    SWIFT_CODE: 'SWIFT  Code',
    E_G_ABCDEFGXXXX: 'Par exemple : ABCD  EF  GX  XXX',
    PLEASE_FINISH_THE_SWIFT_CODE: 'Veuillez compléter le SWIFT  Code',

    DIALOG_CONFIRMATION_INFORMATION:
      'Assurez-vous que les informations sont correctes avant de les soumettre',
  },
  OTHER: {
    //搜索
    SEARCH: 'Recherche',
    //重置
    RESET: 'Réinitialiser',
    SEE_ALL: 'Voir tous',
    CANCEL: 'Annuler',
    CONFIRM: 'Affirmer',
    SAVE: 'Préserver',
    NEXT: 'Suivant',
    TIPS: 'Notification',
    SUBMIT: 'Soumettre',
    CREATE: 'Créer',
    PUBLISH: 'Publier',

    LOADING: 'En cours',

    SUBMIT_NOW: 'Soumettre maintenant',
    MODIFY: 'Modifier',

    NO_DATA_YET: 'Aucune donnée disponible',

    SIGN_OUT: 'Quitter',

    LOGIN_GOOGLE: 'Connectez-vous avec Google',
    LOGIN_FACEBOOK: 'Connectez-vous avec Facebook',
  },

  ERROR: {
    // Link_FORMAT_ERROR
    Link_FORMAT_ERROR: 'Erreur dans le format du lien',
    // aite 标识符不存在
    AITE_IDENTIFIER_NOT_EXIST:
      'Manque de l’identificateur @ avant le titre du Chapitre {chapter_index}.',
    // 章节内容字数多
    CHAPTER_CONTENT_TOO_LONG:
      "Le contenu du Chapitre {chapter_index} dépasse la limite de mots, veuillez le réviser de manière à ce qu'il compte 500-3000 mots par chapitre. Veuillez réessayer après avoir révisé.",

    // 章节索引错误
    CHAPTER_INDEX_ERROR:
      'Le titre du Chapitre {chapter_index} de la ligne {index} ne peut être vide, ni inférieur à 2 mots, ni supérieur à 10 mots.',
    //索引章节存在
    EXIST_CHAPTER_INDEX:
      'Index {chapter_index} déjà existé, veuillez le modifier.',

    // 最后一张索引错误
    LAST_CHAPTER_INDEX_ERROR:
      "Index du dernier chapitre est {chapter_index}, veuillez réviser l'index du chapitre dans l'ordre.",

    // 文件格式错误，请修改
    FILE_FORMAT_ERROR: 'Wrong format, please check it and retry.',

    //章节标题字数过多或过少
    CHAPTER_TITLE_ERROR: 'Titre du chapitre（2-10 caractères ）',

    //章节标题存在
    EXIST_CHAPTER_INDEX_DROP:
      'Index {chapter_index} déjà existé, veuillez le modifier.',

    // 参数错误
    PARAMETER_ERROR: 'Erreur de paramètre',

    // 故事审核中，不可修改
    STORY_AUDITING: "L'histoire est en cours de révision",
    // 章节审核中，不可修改
    CHAPTER_AUDITING: 'Le chapitre est en cours de révision',

    // 故事名字重复
    STORY_NAME_REPEAT: "Ce nom d'histoire est déjà utilisé",

    ERROR: 'Erreur',
    SUCCESS: 'Succès',
    WARNING: 'Attention',

    //故事名称超过64个字符
    STORY_NAME_TOO_LONG:
      'Le nom de l’histoire ne peut pas dépasser 64 caractères',
    //故事ID超过64个字符
    STORY_ID_TOO_LONG: 'L’ID de l’histoire ne peut pas dépasser 64 caractères',

    CREATE_SUCCESS: 'Création  réussie',
    CREATE_FAIL: 'Création  échouée',
    CREATE_EXCEPTION: 'Création  anormale',

    SAVE_SUCCESS: 'Enregistrement  réussi',
    SAVE_FAIL: 'Enregistrement  échoué',
    SAVE_EXCEPTION: 'Enregistrement  anormal',

    EDITOR_SUCCESS: 'Modification réussie',
    EDITOR_FAIL: 'Modification échouée',
    EDITOR_EXCEPTION: 'Modification anormale',

    DELETE_SUCCESS: 'Suppression  réussie',
    DELETE_FAIL: 'Suppression  échouée',
    DELETE_EXCEPTION: 'Suppression  anormale',

    MARK_SUCCESS: 'Marque réussie',
    MARK_FAIL: 'Marque Échouée',
    MARK_EXCEPTION: 'Marque anormale',

    GET_DEALT_NEEDED_ERROR: 'Échec de la saisie des données du message',
    GET_DEALT_NEEDED_EXCEPTION:
      'Dépravation  de la saisie des données du message',

    GET_STORY_LIST_ERROR: 'Échec de la saisie de la liste des histoires',
    GET_STORY_LIST_EXCEPTION:
      'Dépravation  de la saisie de la liste des histoires  ',

    GET_USER_INFO_ERROR:
      "Échec de la saisie des informations sur l'utilisateur",
    GET_USER_INFO_EXCEPTION:
      "Dépravation  de la saisie des informations sur l'utilisateur",

    COVER_SIZE_2_ERROR: "La taille de l'image ne doit pas dépasser 2M",
    FILE_SIZE_LARGER_ERROR: 'La taille du fichier ne doit pas dépasser 2M',
    FILE_TYPE_ERROR: 'Format de fichier incorrect',

    AVATAR_FORMAT_ERROR: "Erreur du format de l'image(JPG,PNG,JPEG)",

    EDIT_USER_INFO_SUCCESS:
      "Modifications réussies  des informations de l'utilisateur",
    EDIT_USER_INFO_ERROR:
      "Modifications échouées  des informations de l'utilisateur",
    EDIT_USER_INFO_EXCEPTION:
      "Modifications anormales des informations de l'utilisateur",

    GET_IDENTITY_INFO_ERROR:
      "Impossible d'obtenir les informations sur l'identité",
    GET_IDENTITY_INFO_EXCEPTION:
      "Saisie anormale des informations  sur l'identité",
    CREATE_IDENTITY_INFO_ERROR:
      "Échec de la création des informations d'identité",
    CREATE_IDENTITY_INFO_EXCEPTION:
      'Création anormale des informations d’identité ',

    GET_ACCOUNT_INFO_ERROR: 'Échec de la création des informations du compte',
    GET_ACCOUNT_INFO_EXCEPTION: 'Saisie anormale des informations du compte',
    CREATE_ACCOUNT_INFO_ERROR:
      'Échec de la création des informations du compte',
    CREATE_ACCOUNT_INFO_EXCEPTION:
      'Création anormale des informations du compte',

    CONTRACT_ERROR: 'Erreurs de signature',
    CONTRACT_EXCEPTION: 'Signature anormale',

    GET_GENRE_LIST_ERROR: "Impossible d'obtenir la liste des catégories",
    GET_GENRE_LIST_EXCEPTION: 'Saisie anormale de la liste des catégories',

    GET_STORY_DETAIL_ERROR: "Impossible d'obtenir les détails de l'histoire",
    GET_STORY_DETAIL_EXCEPTION: 'Saisie anormale des détails de l’histoire',

    EGT_TAG_LIST_ERROR: "Impossible d'obtenir la liste des étiquettes",
    EGT_TAG_LIST_EXCEPTION: 'Saisie anormale de la liste des étiquettes',

    UPLOAD_COVER_ERROR:
      'Le téléchargement de la couverture a échoué, veuillez réessayer',

    GET_CHAPTER_LIST_ERROR: "Impossible d'obtenir la liste des chapitres",
    GET_CHAPTER_LIST_EXCEPTION: 'Saisie anormale de la liste des chapitres',

    GET_CONTENT_ERROR: "Impossible d'obtenir le contenu",
    GET_CONTENT_EXCEPTION: 'Saisie anormale du contenu',

    SELECT_CHAPTER_ERROR: 'Veuillez sélectionner le  chapitre',

    PUBLISH_SUCCESS: 'Publier avec succès',
    PUBLISH_ERROR: 'Publier avec erreur',
    PUBLISH_EXCEPTION: 'Publication anormale',
    PUBLISH_AND_CANNOT_MODIFIED: 'Publié et non modifiable  maintenant',

    PLEASE_FINISH_TITLE_CONTENT: 'Veuillez compléter le titre et le contenu',

    STORY_WITH_500_3000_WORDS:
      'Veuillez entrer de  500 à 3000 mots  dans ce chapitre',

    CHAPTER_TITLE_30_WORDS: 'Le titre ne doit pas dépasser 30 mots',

    CHAPTER_CONTENT_CANNOT_BE_MORE:
      'Le contenu du chapitre ne doit pas dépasser  3000 mots',

    PUBLISH_TIME_ERROR:
      "Impossible de publier ce chapitre plus tôt à l'heure actuelle",
  },
  COUNTRY_REGION: {
    SS: 'Afrique du Sud',
    ME: 'République de Macédoine',
    SX: 'Soudan',
    RS: 'Serbie',
    SM: 'Saint-Marin',
    AF: 'Afghanistan',
    AX: 'Îles Åland',
    AL: 'Albanie  ',
    DZ: 'Algérie',
    AS: 'Samoa américaines',
    AD: 'Andorre',
    AO: 'Angola',
    AI: 'Anguilla',
    AQ: 'Antarctique',
    AG: 'Antigua-et-Barbuda',
    AR: 'Argentine',
    AM: 'Arménie',
    AW: 'Aruba',
    AU: 'Australie',
    AT: 'Autriche  ',
    AZ: 'Azerbaïdjan',
    BS: 'Bahamas',
    BH: 'Bahreïn',
    BD: 'Bangladesh',
    BB: 'Barbade',
    BY: 'Bielorussie',
    BE: 'Belgique',
    BZ: 'Belize',
    BV: 'Île Bouvet',
    BJ: 'Bénin',
    BM: 'Bermudes',
    BT: 'Bhutan',
    BO: 'Bolivie',
    BQ: 'Caraïbes Pays-Bas',
    BA: 'Bosnie-Herzégovine',
    BW: 'Botswana',
    BR: 'Brésil  ',
    IO: "Territoire britannique de l'océan Indien",
    BN: 'Brunei',
    BG: 'Bulgarie',
    BF: 'Burkina Faso',
    BI: 'Burundi',
    KH: 'Cambodge',
    CM: 'Cameroun',
    CA: 'Canada  ',
    CV: 'Cap-Vert',
    KY: 'Îles Caïmans',
    CF: 'République Centrafricaine',
    TD: 'Tchad',
    CL: 'Chili',
    CN: 'République populaire de Chine',
    CX: 'Île Christmas',
    CC: 'Îles Cocos  ',
    CO: 'Columbia',
    KM: 'Comores  ',
    CG: 'République du  Congo',
    CD: 'République démocratique du Congo',
    CK: 'Îles Cook',
    CR: 'Costa Rica  ',
    CI: "Côte d'Ivoire",
    HR: 'Croatie  ',
    CU: 'Cuba',
    CW: 'Curaçao',
    CY: 'Chypre',
    CZ: 'République tchèque',
    DK: 'Danemark',
    DJ: 'Djibouti ',
    DM: 'Dominique',
    DO: 'République dominicaine',
    EC: 'Équateur',
    EG: 'Egypte',
    SV: 'Salvador',
    GQ: 'Guinée équatoriale',
    ER: 'Érythrée',
    EE: 'Estonie',
    ET: 'Ethiopie',
    FK: 'Îles Falkland',
    FO: 'Îles Féroé',
    FJ: 'Fiji',
    FI: 'Finlande',
    FR: 'France',
    GF: 'Guyane française',
    PF: 'Polynésie française',
    TF: 'Territoires du sud de la France',
    GA: 'Gabon',
    GM: 'Gambie',
    GE: 'Géorgie',
    DE: 'Allemagne',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GR: 'Grèce',
    GL: 'Groenland',
    GD: 'Grenade',
    GP: 'Guadeloupe',
    GU: 'Guam',
    GT: 'Guatemala',
    GG: 'Guernesey',
    GN: 'Guinée',
    GW: 'Guinée-Bissau',
    GY: 'Guyane',
    HT: 'Haïti',
    HM: 'Île Heard et îles Macdonald',
    VA: 'Vatican',
    HN: 'Honduras',
    HK: 'Hong-Kong',
    HU: 'Hongrie',
    IS: 'Islande',
    IN: 'Inde',
    ID: 'Indonésie',
    IR: 'Iran',
    IQ: 'Iraq',
    IE: 'Irlande  ',
    IM: 'Île de Man',
    IL: 'Israël',
    IT: 'Italie',
    JM: 'Jamaïque',
    JP: 'Japon',
    JE: 'Jersey',
    JO: 'Jordanie',
    KZ: 'Kazakhstan',
    KE: 'Kenya',
    KI: 'Kiribati',
    KP: 'Corée  du Sud',
    KR: 'Corée  du Nord',
    KW: 'Koweït',
    KG: 'Kirghizstan  ',
    LA: 'Laos',
    LV: 'Lettonie',
    LB: 'Liban',
    LS: 'Lesotho',
    LR: 'Liberia',
    LY: 'Libye',
    LI: 'Liechtenstein',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    MO: 'Macao',
    MK: 'Macédoine  ',
    MG: 'Madagascar',
    MW: 'Malawi',
    MY: 'Malaisie',
    MV: 'Maldives',
    ML: 'Mali',
    MT: 'Malte',
    MH: 'Îles Marshall',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MU: 'Maurice  ',
    YT: 'Île comorienne de Mayotte',
    MX: 'Mexique',
    FM: 'États fédérés de Micronésie',
    MD: 'Moldova',
    MC: 'Monaco',
    MN: 'Mongolie',
    MS: 'Montserrat  ',
    MA: 'Maroc',
    MZ: 'Mozambique',
    MM: 'Birmanie',
    NA: 'Namibie',
    NR: 'Nauru',
    NP: 'Népal',
    NL: 'Hollande  ',
    AN: 'Antilles néerlandaises',
    NC: 'Nouvelle-Calédonie',
    NZ: 'Nouvelle-Zélande',
    NI: 'Nicaragua',
    NE: 'Niger',
    NG: 'Nigéria',
    NU: 'Nioué',
    NF: 'Île Norfolk',
    MP: 'Îles Mariannes septentrionales',
    NO: 'Norvège',
    OM: 'Oman',
    PK: 'Pakistan',
    PW: 'Palaos',
    PS: 'Palestine',
    PA: 'Panama  ',
    PG: 'Papouasie-Nouvelle-Guinée',
    PY: 'Paraguay  ',
    PE: 'Pérou',
    PH: 'Philippines',
    PN: 'Îles de Pitcairn',
    PL: 'Pologne',
    PT: 'Portugal  ',
    PR: 'Porto Rico',
    QA: 'Qatar  ',
    RE: 'Réunion',
    RO: 'Roumanie',
    RU: 'Russie',
    RW: 'Rwanda',
    BL: 'Saint-Barthélémy',
    SH: 'Sainte-Hélène',
    KN: 'Saint-Kitts-et-Nevis',
    LC: 'Sainte-Lucie',
    MF: 'Saint-Martin',
    PM: 'Saint-Pierre et Miquelon',
    VC: 'Saint-Vincent-et-les Grenadines',
    WS: 'Samoa-Occidental',
    ST: 'Sao  Tomé-et-Principe',
    SA: 'Arabie Saoudite',
    SN: 'Sénégal  ',
    CS: 'Serbie-et-Monténégro',
    SC: 'Seychelles  ',
    SL: 'Sierra Leone  ',
    SG: 'Singapour',
    SK: 'Slovaquie',
    SI: 'Slovénie',
    SB: 'Iles Salomon',
    SO: 'Somalie  ',
    ZA: 'Afrique du Sud',
    GS: 'Géorgie du Sud et des îles Sandwich du Sud',
    ES: 'Espagne',
    LK: 'Sri Lanka',
    SD: 'Soudan',
    SR: 'Suriname',
    SJ: 'Svalbard et îles Jan Mayen',
    SZ: 'Swaziland  ',
    SE: 'Suède',
    CH: 'Suisse',
    SY: 'Syrie',
    TJ: 'Tadjikistan  ',
    TW: 'Taiwan',
    TZ: 'Tanzanie',
    TH: 'Thaïlande',
    TL: 'Timor-Leste',
    TG: 'Togo',
    TK: 'Tokélaou',
    TO: 'Tonga',
    TT: 'Trinité et Tobago',
    TN: 'Tunisie',
    TR: 'Turquie',
    TM: 'Turkménistan',
    TC: 'Îles Turques et Caïques',
    TV: 'Tuvalu',
    UG: 'Ouganda',
    UA: 'Ukraine',
    AE: 'Emirats Arabes Unis  ',
    GB: 'Angleterre',
    US: 'Etats-Unis',
    UM: 'Samoa américaines',
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VU: 'Vanuatu',
    VE: 'Venezuela',
    VN: 'Viêtnam',
    WF: 'Wallis-et-Futuna',
    EH: 'Sahara occidental',
    YE: 'Yémen',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
    VI: 'Îles Vierges américaines',
    VG: 'Îles Vierges britanniques',
  },
};
