// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Choose your language"
  },

  MENU: {
    HOME: "Home",
    STORIES: "Stories",
    CHAPTER: "Chapter",
    INCOME: "Income",
    PROFILE: "Profile"
  },

  BOOK: {
    STORY_NAME: "Story Name",
    // 输入故事名称
    INPUT_STORY_NAME: "Input story name",
    //输入故事ID
    INPUT_STORY_ID: "Input story ID",

    MY_STORIES: "My Stories",
    WORDS: "Words",
    CHAPTERS: "Chapters",
    SUBSCRIBERS: "Subscribers",
    VIEWS: "Views",
    NEW_STORY: "New Story",
    LAST_UPDATED: "Last Updated",
    EDIT_STORY: "Edit Story",
    DELETE_STORY: "Delete Story",
    DELETE_STORY_CONFIRM: "Are you sure to delete the story?",

    ONGOING: "Ongoing",
    COMPLETED: "Completed",

    APPLY_FOR_CONTRACT: "Apply for Contract",
    REVIEW_DISAPPROVED: "Review Disapproved",
    REVIEW_APPROVED: "Review Approved",
    REJECTED_REASON: "Rejected Reason",
    YOU_HAVE_NOT_CREATED_A_STORY_YET: "You have not created a story yet.",

    // 批量导入章节
    BATCH_IMPORT_CHAPTER: "Batch Import Chapter",

    // 章节上传失败，点击重试
    UPLOAD_FAILED_CLICK_TO_RETRY: "Chapter upload failed, click to retry",
    // 章节上传中
    CHAPTER_UPLOADING: "Chapter Uploading",
    // 章节重试长传成功
    CHAPTER_RETRY_UPLOAD_SUCCESS: "Chapter retry upload success",
    // 重试失败请重新上传章节
    CHAPTER_RETRY_UPLOAD_FAILED:
      "Chapter retry upload failed, please reupload chapter",
    // 章节正在后台上传，请稍后5分钟
    CHAPTER_UPLOADING_PLEASE_WAIT: "Chapter uploading, please wait 5 minutes",

    // 故事名字重复
    STORY_NAME_REPEAT: "Story name repeat",

    // 故事已完结
    STORY_FINISHED: "Story Finished",

    PLEASE_CLICK_TO_UPLOAD: "Please click to upload.",
    CREATE_BASIC_INFORMATION_OF_BOOK_BEFORE_IMPORTING_NEW_CHAPTERS:
      "Create basic information of book before importing new chapters.",
    FILE_NAME: "File Name",
    FILE_SIZE: "File Size",
    UPLOAD_DATE: "Upload Date",
    TXT_FORMAT_SUPPORTED: "TXT Format Supported",
    HOW_TO_OBTAIN_TXT_FILES: "How to obtain TXT files?",
    FOR_MAC:
      "For Mac: Drag and drop TXT files into the book folder in the application.",
    FOR_WINDOWS:
      "For Windows: Drag and drop TXT files into the book folder in the application.",
    TXT_FORMAT_EXAMPLE: "TXT Format Example",
    FORMAT_TEMPLATE_DOWNLOAD: "Format Template Download",
    CHAPTER_INDEX: "Chapter Index",
    CHAPTER_NAME: "Chapter Name",
    CHAPTER_CONTENT: "Chapter Content",

    COVER_SIZE: "Cover Size",
    SMALLER_THAN_2_MB: "Smaller than 2 MB",
    FORMAT: "Format",

    TITLE: "Title",
    TITLE_ERROR: "Between 3 and 64 characters",

    WORK_TYPE: "Work Type",
    ORIGINAL_WORK: "Original Work",
    TRANSLATION: "Translation Work",

    PEN_NAME_SHOW: "Pen Name Show",
    SHOW: "Show",
    HIDE: "Hide",

    LANGUAGE: "Language",

    CONTENT_RATION: "Content Ration",
    ADULTS: "Adults(18+)",
    YOUNG_ADULTS: "Young Adults(16+)",
    TEENS: "Teens(13+)",

    GENRE: "Genre",
    //选择分类
    SELECT_GENRE: "Select Genre",

    GENRE_ERROR: "Between 3 and 64 characters",

    TAGS: "Tags",
    TAGS_ERROR: "Select Tags",
    ONLY_7_TAGS: "7 options at most, select 1 at least in each type",

    INTRODUCTION: "Introduction",
    INTRODUCTION_ERROR: "Introduction (10-500 characters)",

    OTHER_INFO: "Other Info",
    BOOK_ID: "Book ID",
    SIGN_STATUS: "Sign Status",

    SIGNED: "Signed",
    UNSIGNED: "Unsigned",

    UNDER_REVIEW: "Under Review",
    REVIEW_REJECTED: "Review Rejected",
    TO_BE_SIGNED: "To Be Signed",

    CREATE_TIME: "Create Time",
    UPDATE_TIME: "Update Time",
    STATUS: "Status",

    MARK_AS_COMPLETED: "Mark as Completed",
    MARK_AS_COMPLETED_MAKE_SURE_STORY:
      "Make sure you have finished the story before marking it as completed.",

    MARK_APPLICATION: "Mark as Complete",
    IN_APPLICATION: "In application",
    APPLICATION_FAILED: "Application failed"
  },

  CHAPTER: {
    NEW_CHAPTER: "New Chapter",
    ALL_CHAPTER: "All Chapters",
    DELETE_CHAPTER: "Delete Chapter",

    CHAPTER: "Chapter",
    UNTITLED_CHAPTER: "Untitled Chapter",
    WORDS: "Words",

    // 发布的状态
    UNPUBLISHED: "Unpublished", // 0未发布 算草稿
    PUBLISHING: "Publishing", // 1发布中
    PUBLISHED: "Published", // 2已发布成功
    PUBLISH_FAILED: "Publish failed", // 3发布失败

    // 审核中
    NOT_REVIEWED: "Not reviewed", // 0未审核
    REVIEW_DISAPPROVED: "Review Disapproved", // 1未通过
    REVIEW_PASSED: "Review passed", // 2审核通过
    REVIEWING: "Reviewing", // 3审核中

    // 其他
    SCHEDULED_PUBLISH: "Scheduled Publish", // 定时发布
    UNDER_REVIEW: "Under Review",

    ENTER_CHAPTER_NAME: "Enter Chapter Name",
    START_WRITING_HERE_500_3000_WORDS: "Start writing here (500-3000 words)",

    THIS_CHAPTER_SCHEDULED_PUBLISH_AT: "This chapter scheduled publish at",

    PREVIOUS_CHAPTER: "Previous Chapter",

    PUBLISH_CHAPTER: "Publish Chapter",

    PUBLISH_TIME: "Publish Time",
    NOW: "Now",
    LATER: "Later",

    DELETE_CHAPTER_TIPS: "Delete chapter tips？",
    DELETE_CHAPTER_TIPS_CONTENT: "Are you sure to delete the chapter?",

    LAST_CHAPTER_CANNOT_EMPTY: "Last chapter cannot be empty",

    EACH_CHAPTER_LESS_CHAPTER_3000_WORDS: "Each chapter less than 3000 words",

    // 存在未发布章节
    EXIST_UNPUBLISHED_CHAPTER: "Exist unpublished chapter"
  },

  AUTH: {
    // 推荐人
    REFERFNCE: "Recommender",

    SELECT_RULE: "Select Rule",

    // 点击绑定
    CLICK_TO_BIND: "Click to bind",
    //存在绑定关系，无法重复绑定
    EXIST_BIND_RELATION: "Exist bind relation, cannot bind again",
    MONTH_UPDATED_WORDS: "words updated in the current natural month",
    ACCUMULATED_INCOME: "Accumulated Income",
    MONEY_SIGN: "$",

    NOTIFICATIONS: "Notifications",

    ACCOUNT_INFO: "Account Info",

    PROFILE: "Profile",

    USER_NAME: "User Name",
    USER_NAME_ERROR: "Between 3 and 32 characters",

    GENDER: "Gender",
    FEMALE: "Female",
    MALE: "Male",
    RATHER_NOT_SAY: "Rather not say",

    BIRTHDAY: "Birthday",
    SELECT_YOUR_BIRTHDAY: "Please choose your birthday",

    BIO: "Bio",
    ENTER_YOUR_BIO: "Enter your bio",
    BIO_ERROR: "Introduction (10-500 characters)",

    FACEBOOK_PROFILE_URL: "Facebook Profile URL",
    FACEBOOK_ERROR: "Facebook Profile (up to 255 characters)",

    TWITTER_PROFILE: "Twitter Profile URL",
    TWITTER_ERROR: "Twitter Profile (up to 255 characters)",

    IDENTITY_INFO: "Identity Info",

    NATIONAL_ID: "National ID",
    CHANGE_NATIONAL_ID: "Change National ID",
    REMOVE_NATIONAL_ID: "Remove National ID",

    ID_NUMBER: "ID Number",
    ENTER_YOUR_ID_NUMBER: "Enter your ID number",
    PLEASE_FINISH_THE_ID_NUMBER: "Please finish your ID number",
    REAL_NAME: "Real Name",
    PLEASE_FINISH_THE_REAL_NAME: "Please finish your real name",
    BETWEEN_3_TO_32_CHARACTERS: "Between 3 and 32 characters",
    COUNTRY_REGION: "Country/Region",
    PLEASE_FINISH_THE_COUNTRY_REGION: "Please finish your country/region",
    EMAIL: "Email",
    PLEASE_FINISH_THE_EMAIL: "Please finish your email",
    WRONG_EMAIL_FORMAT: "Wrong email format",
    PHONE: "Phone",
    PLEASE_FINISH_THE_PHONE: "Please finish your phone",
    ADDRESS: "Address",
    PLEASE_FINISH_THE_ADDRESS: "Please finish your address",
    PEN_NAME: "Pen Name",
    PLEASE_FINISH_THE_PEN_NAME: "Please finish your pen name",
    EDITOR: "Editor",
    RANDOM: "Random",
    MANUAL: "Manual",
    PLEASE_FINISH_THE_EDITOR: "Please finish your editor",

    SELECT_YOUR_EDITOR: "Please select your editor",

    TRANSACTION_FEE:
      "The transaction fee shall be borne by the author. And the amount of transaction fee varies according to different payment methods.",
    PAYMENT_METHOD:
      "We strongly recommend you to choose Payoneer as the payment method for receiving the payment. Payoneer reduces commision fee, which will result in more efficiency for you. If Payoneer is currently not available in your country, please select other payment options listed below.",
    DIFFERENT_COMMISSIONS:
      "The commission fee charged by PayPal or Payoneer varies based on the country you are in. Please check out your local PayPal or Payoneer website for exact information.",
    TRANSACTION_FEE_BANK:
      "Bank transfer will also result in a bank transaction fee. The transaction fee varies based on regions of banks. Please contact your local bank for detailed information.",
    ANY_QUESTIONS:
      "If you have any questions or concerns, please feel free to contact your editor",

    PAY_PAL_EMAIL: "PayPal email address",
    ILLEGAL_PAPAL_EMAIL: "Illegal PayPal Email",
    RECEIVER_EMAIL: "Receiver's Name",
    PLEASE_ENTER_RECEIVER_EMAIL: "Please enter receiver email",
    COLLECTION_SECURITY:
      "To ensure the security of receiving payment, we need you to confirm whether we can pay you on payoneer website. Please click on the button of \"Receiving authorization\" to confirm it.",
    RECEIVING_AUTHORIZATION: "Receiving Authorization",

    BANK: "Bank",
    BANK_NAME: "Bank Name",
    E_G_BANK_OF_CHINA: "e.g. State Bank",
    PLEASE_FINISH_THE_BANK_NAME: "Please finish your bank name",
    ACCOUNT_HOLDER_NAME: "Account Holder Name",
    E_G_LI: "e.g. John Smith",
    PLEASE_FINISH_THE_ACCOUNT_HOLDER_NAME:
      "Please finish your account holder name",
    ACCOUNT_NUMBER: "Account Number",
    E_G_2188996688: "e.g. 2188996688",
    PLEASE_FINISH_THE_ACCOUNT_NUMBER: "Please finish your account number",
    SWIFT_CODE: "Swift Code",
    E_G_ABCDEFGXXXX: "e.g. ABCDGBMMXXX",
    PLEASE_FINISH_THE_SWIFT_CODE: "Please finish your swift code",

    DIALOG_CONFIRMATION_INFORMATION: "Confirmation Information"
  },
  OTHER: {
    //搜索
    SEARCH: "Search",
    //重置
    RESET: "Reset",

    SEE_ALL: "See All",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    SAVE: "Save",
    NEXT: "Next",
    TIPS: "Tips",
    SUBMIT: "Submit",
    CREATE: "Create",
    PUBLISH: "Publish",

    LOADING: "Loading",

    SUBMIT_NOW: "Submit Now",
    MODIFY: "Modify",

    NO_DATA_YET: "No data yet",

    SIGN_OUT: "Sign Out",

    LOGIN_GOOGLE: "Sign in with Google",
    LOGIN_FACEBOOK: "Sign in with Facebook"
  },

  ERROR: {
    // aite 标识符不存在
    AITE_IDENTIFIER_NOT_EXIST:
      "The {index}st line of the chapter  Chaper {chapter_index} @ inexistent ID",

    // url 格式错误
    Link_FORMAT_ERROR: "Link format error",

    // 章节索引错误
    CHAPTER_INDEX_ERROR:
      "The chapter name of chapter {chapter_index} in the {index}st line can't be blank, it should be more than 2 words and less than 10 words",

    //索引章节存在
    EXIST_CHAPTER_INDEX:
      "Index {chapter_index}  Existed index, please alter it.",

    // 最后一张索引错误
    LAST_CHAPTER_INDEX_ERROR:
      "The index of the last chapter is {chapter_index}. please alter the index in order.",

    // 章节内容字数多
    CHAPTER_CONTENT_TOO_LONG:
      "Chapter name {chapter_index}  The word count exceeds the required number,  it should be between 500-3000, please modify it and retry.",

    // 文件格式错误，请修改
    FILE_FORMAT_ERROR: "Wrong format, please check it and retry.",

    //章节标题字数在1到10之间
    CHAPTER_TITLE_ERROR: "Title (1-20 characters)",
    // 不能都是数字
    CHAPTER_TITLE_ERROR_1: "Cannot be all numbers. Please enter a meaningful title.",
    // 不能都是Chapter 开头
    CHAPTER_TITLE_ERROR_2: "Cannot start with chapter. Please enter a meaningful title.",

    //章节标题存在
    EXIST_CHAPTER_INDEX_DROP:
      "Please don \"Chapter index\" is added to the title, the system will automatically stitch.",
    // 参数错误
    PARAMETER_ERROR: "Parameter error",

    // 故事审核中，不可修改
    STORY_AUDITING: "Story is auditing, cannot modify",

    // 章节审核中，不可修改
    CHAPTER_AUDITING: "Chapter is auditing, cannot modify",

    //故事名称超过64个字符
    STORY_NAME_TOO_LONG: "Story name is too long, please check it and retry.",
    //故事ID超过64个字符
    STORY_ID_TOO_LONG: "Story ID is too long, please check it and retry.",

    ERROR: "Error",
    SUCCESS: "Success",
    WARNING: "Warning",

    CREATE_SUCCESS: "Create Success",
    CREATE_FAIL: "Create Fail",
    CREATE_EXCEPTION: "Create Exception",

    SAVE_SUCCESS: "Save Success",
    SAVE_FAIL: "Save Fail",
    SAVE_EXCEPTION: "Save Exception",

    EDITOR_SUCCESS: "Editor Success",
    EDITOR_FAIL: "Editor Fail",
    EDITOR_EXCEPTION: "Editor Exception",

    DELETE_SUCCESS: "Delete Success",
    DELETE_FAIL: "Delete Fail",
    DELETE_EXCEPTION: "Delete Exception",

    MARK_SUCCESS: "Mark Success",
    MARK_FAIL: "Mark Fail",
    MARK_EXCEPTION: "Mark Exception",

    GET_DEALT_NEEDED_ERROR: "Get Dealt Needed Error",
    GET_DEALT_NEEDED_EXCEPTION: "Get Dealt Needed Exception",

    GET_STORY_LIST_ERROR: "Get Story List Error",
    GET_STORY_LIST_EXCEPTION: "Get Story List Exception",

    GET_USER_INFO_ERROR: "Get User Info Error",
    GET_USER_INFO_EXCEPTION: "Get User Info Exception",

    COVER_SIZE_2_ERROR: "Picture size cannot exceed 2MB",
    FILE_SIZE_LARGER_ERROR: "File size cannot exceed 2MB",
    FILE_TYPE_ERROR: "Wrong file type",

    AVATAR_FORMAT_ERROR: "Avatar format error",

    EDIT_USER_INFO_SUCCESS: "Edit User Info Success",
    EDIT_USER_INFO_ERROR: "Edit User Info Error",
    EDIT_USER_INFO_EXCEPTION: "Edit User Info Exception",

    GET_IDENTITY_INFO_ERROR: "Get Identity Info Error",
    GET_IDENTITY_INFO_EXCEPTION: "Get Identity Info Exception",
    CREATE_IDENTITY_INFO_ERROR: "Create Identity Info Error",
    CREATE_IDENTITY_INFO_EXCEPTION: "Create Identity Info Exception",

    GET_ACCOUNT_INFO_ERROR: "Get Account Info Error",
    GET_ACCOUNT_INFO_EXCEPTION: "Get Account Info Exception",
    CREATE_ACCOUNT_INFO_ERROR: "Create Account Info Error",
    CREATE_ACCOUNT_INFO_EXCEPTION: "Create Account Info Exception",

    CONTRACT_ERROR: "Contract Error",
    CONTRACT_EXCEPTION: "Contract Exception",

    GET_GENRE_LIST_ERROR: "Get Genre List Error",
    GET_GENRE_LIST_EXCEPTION: "Get Genre List Exception",

    GET_STORY_DETAIL_ERROR: "Get Story Detail Error",
    GET_STORY_DETAIL_EXCEPTION: "Get Story Detail Exception",

    EGT_TAG_LIST_ERROR: "Get Tag List Error",
    EGT_TAG_LIST_EXCEPTION: "Get Tag List Exception",

    UPLOAD_COVER_ERROR: "Failed to upload cover, please try again",

    GET_CHAPTER_LIST_ERROR: "Get Chapter List Error",
    GET_CHAPTER_LIST_EXCEPTION: "Get Chapter List Exception",

    GET_CONTENT_ERROR: "Get Content Error",
    GET_CONTENT_EXCEPTION: "Get Content Exception",

    SELECT_CHAPTER_ERROR: "Select Chapter Error",

    PUBLISH_SUCCESS: "Publish Success",
    PUBLISH_ERROR: "Publish Error",
    PUBLISH_EXCEPTION: "Publish Exception",
    PUBLISH_AND_CANNOT_MODIFIED: "Published cannot modified",
    REVIEWING_AND_CANNOT_MODIFIED: "Reviewing cannot modified",
    NOT_FIRST_CANNOT_PUBLISHED: "Not first cannot published",
    NOT_FIRST_CANNOT_SAVE: "Not first cannot saved",

    PLEASE_FINISH_TITLE_CONTENT: "Please finish title and content",
    PLEASE_FINISH_CHAPTER_TITLE: "Please finish title",
    PLEASE_FINISH_CHAPTER_CONTENT: "Please finish content",

    STORY_WITH_500_3000_WORDS: "Story with 500-3000 words",

    CHAPTER_TITLE_10_WORDS: "Chapter title 30 words",

    CHAPTER_CONTENT_CANNOT_BE_MORE:
      "Chapter content cannot be more than 3000 words",

    PUBLISH_TIME_ERROR: "Publish time error"
  },

  COUNTRY_REGION: {
    AF: "Afghanistan",
    AX: "Åland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Plurinational State of",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, the Democratic Republic of the",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea, Democratic People's Republic of",
    KR: "Korea, Republic of",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia, the former Yugoslav Republic of",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Moldova, Republic of",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan, Province of China",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Minor Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
  }
};
