import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/homeroot",
          name: "homeroot",
          component: () => import("@/view/pages/home/HomeRoot.vue"),
          children: [
            {
              path: "/home",
              name: "home",
              component: () => import("@/view/pages/home/Home.vue")
            },
            {
              path: "/profile",
              name: "profile",
              component: () => import("@/view/pages/home/Profile.vue")
            }
          ]
        },
        {
          path: "/storiesroot",
          name: "storiesroot",
          component: () => import("@/view/pages/stories/StoriesRoot.vue"),
          children: [
            {
              path: "/stories",
              name: "stories",
              component: () => import("@/view/pages/stories/Stories.vue")
            },
            {
              path: "/chapter",
              name: "chapter",
              component: () => import("@/view/pages/stories/StoryChapter.vue")
            },
            {
              path: "/newstories",
              name: "newstories",
              component: () => import("@/view/pages/stories/StoryNew.vue")
            }
          ]
        },
        {
          path: "/incomeroot",
          name: "incomeroot",
          component: () => import("@/view/pages/income/IncomeRoot.vue"),
          children: [
            {
              path: "/income",
              name: "income",
              component: () => import("@/view/pages/income/Income.vue")
            }
          ]
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Login")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/Register")
        },
        {
          name: "editorbundle",
          path: "/editorbundle",
          component: () => import("@/view/pages/auth/EditorBundle")
        },
        {
          name: "test",
          path: "/test",
          component: () => import("@/view/pages/test")
        },
        {
          name: "test2",
          path: "/test2",
          component: () => import("@/view/pages/test3")
        },
      ]
    },
    {
      path: "*",
      redirect: "/404"
    }
  ]
});
